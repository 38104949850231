import React, { useEffect } from "react";
import { useBarcode } from "@createnextapp/react-barcode";
import { useReactToPrint } from "react-to-print";

const GenerateBarCodeImage = (props: any) => {
  const { generatedBarCode } = props;
  const { inputRef } = useBarcode({
    value: generatedBarCode,
    options: {
      background: "#ccffff",
    },
  });
  const handlePrint = useReactToPrint({
    content: () => inputRef.current,
  });

  useEffect(() => {
    handlePrint();
  }, [generatedBarCode]);

  return (
    <>
      <img ref={inputRef} title="Barcode" alt="Barcode" />
    </>
  );
};

export default GenerateBarCodeImage;
