import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { bill_history_db, fetchBillHistoryToDB } from "../../../data/indexedDB";
import BillDetailsModal from "./BillDetailsModal";
import { filterBillHistoryListBySearchString } from "./billHistoryTableFilter";
import RefundDetailsModal from "./RefundDetailsModal";
import "./styles.scss";
import TableContainer from "../../../components/Basic/TableContainer/TableContainer";
import { getMethod } from "../../../api/basic_configs/appService";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { Container } from "react-bootstrap";

export default function () {
  const [isBillDetailsModalOpen, setIsBillDetailsModalOpen] = useState(false);
  const [billData, setBillData] = useState<any>({});
  const [billHistoryListData, setBillHistoryListData] = useState([]);
  const [billHistoryListDataStatic, setBillHistoryListDataStatic] = useState(
    []
  );
  const [isListLoading, setIsListLoading] = useState(true);
  const [billingHistoryDetails, setBillingHistoryDetails] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filterData, setFilterData] = useState<any>([]);
  const [storeData, setStoreData] = useState<any>({});
  const filterDataRef = useRef<Array<Object>>();
  const userDetails = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");

  const getBillingHistory = async () => {
    try {
      let url = API_END_POINTS.GET_ALL_BILL_HISTORY.replace(
        ":store_id",
        userDetails.registeration_id
      );
      const resp = await getMethod(url);
      const respData = resp.data?.reverse();
      let bilHist: any = [];
      respData.forEach((data: any, i: number) => {
        if (data.login_type[0] == getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0]) {
          bilHist.push(data);
        }
      });
      const addSNOToResponse = bilHist.map((value: any, index: number) => {
        return { ...value, SNo: index + 1 };
      });

      setBillingHistoryDetails(addSNOToResponse);

      handleBillHistory(respData);

      filterDataRef.current = respData;
    } catch (err) {}
  };

  const getStoreDetail = async () => {
    try {
      let url = API_END_POINTS.GET_STORE_DETAILS.replace(
        ":request_id",
        userDetails.registeration_id
      );
      const response = await getMethod(url);
      const respData = response.data;
      setStoreData(respData);
    } catch (error) {}
  };

  useEffect(() => {
    getBillingHistory();
    getStoreDetail();
  }, []);

  const handleBillHistory = (respData: Array<Object>) => {
    const filteredBillData = respData.filter(
      (value: any) => value.refund_status == false
    );

    setFilterData(filteredBillData);
    filterDataRef.current = filteredBillData;
  };

  const getBillHistoryDataFromIDB = async () => {
    let data = await bill_history_db.data.toArray();

    if (data.length != 0) {
      setBillHistoryListData(data);
      setBillHistoryListDataStatic(data);
      setIsListLoading(false);
    } else {
      fetchBillHistoryToDB();
      getBillHistoryDataFromIDB();
    }
  };

  const handleSearchAndFilter = () => {
    if (searchString == "") {
      setBillHistoryListData(billHistoryListDataStatic);
    } else {
      let billHistoryList = filterBillHistoryListBySearchString(
        searchString,
        billHistoryListDataStatic
      );
      setBillHistoryListData(billHistoryList);
    }
  };

  const renderBillDetails = () => {
    if (isBillDetailsModalOpen) {
      if (billData.lastRefund) {
        return (
          <RefundDetailsModal
            billData={billData}
            setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
          />
        );
      } else {
        return (
          <BillDetailsModal
            billData={billData}
            storeData={storeData}
            setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
          />
        );
      }
    }
  };

  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "-";
  };
  const columns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Invoice No",
        accessor: "storeInvoiceNumber",
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <div
              className={`sathi-green sathi-green:hover ${
                cellProps.value ||
                cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                  ? "underline"
                  : "text-align-center"
              }`}
            >
              {cellProps.value
                ? cellProps.value
                : cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                ? cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                : "Pending"}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "invoice_info.invoice_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_details.customer_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData ? (
            cellData.charAt(0).toUpperCase() + cellData.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "Contact No",
        accessor: "customer_details.mobile_number",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Payment Method",
        accessor: "payment_method",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = cellProps.row.original.list_of_paymentModes;
          const paymentMethods =
            value && value.length !== 0
              ? value.map((payment: any) => Object.keys(payment)[0])
              : "";
          return paymentMethods ? paymentMethods.join(",") : "";
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData?.toFixed(2);
        },
      },
    ],
    []
  );
  const cafeColumns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Invoice No",
        accessor: "storeInvoiceNumber",
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <div
              className={`sathi-green sathi-green:hover ${
                cellProps.value ||
                cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                  ? "underline"
                  : "text-align-center"
              }`}
            >
              {cellProps.value
                ? cellProps.value
                : cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                ? cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                : "Pending"}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "invoice_info.invoice_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_details.customer_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData ? (
            cellData.charAt(0).toUpperCase() + cellData.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "Contact No",
        accessor: "customer_details.mobile_number",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Payment Method",
        accessor: "payment_method",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = cellProps.row.original.list_of_paymentModes;
          const paymentMethods =
            value && value.length !== 0
              ? value.map((payment: any) => Object.keys(payment)[0])
              : "";
          return paymentMethods ? paymentMethods.join(",") : "";
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData?.toFixed(2);
        },
      },
    ],
    []
  );

  useEffect(() => {
    getBillHistoryDataFromIDB();
  }, []);

  useEffect(() => {
    handleSearchAndFilter();
  }, [searchString]);

  return (
    <Fragment>
      <Container fluid={true}>
        <TableContainer
          columns={isCafe ? cafeColumns : columns}
          data={billingHistoryDetails}
          isGlobalFilter={true}
          className="custom-header-css"
          handleClick={(detail: any) => {
            setIsBillDetailsModalOpen(true);
            setBillData(detail);
          }}
          PlaceholderValue={
            "Search By Invoice Number, Customer Name, Contact Number and Payment Status"
          }
        />
        {renderBillDetails()}
      </Container>
    </Fragment>
  );
}
