import React from "react";
import "./styles.css";
import { close } from "../../../assets/icons";

function Modal(props: any) {
	const { setOpenModal, header, width, height,apiCall } = props;
	return (
		<div className='modalBackground'>
			<div
				style={{ width: width, height: height }}
				className='modalContainer'
			>
				<div className='modal-header-sec'>
					<h2> {header}</h2>
					{(setOpenModal || apiCall) && (
						<img
							src={close}
							onClick={() => {
								apiCall ?  apiCall()  : setOpenModal(false);
								
							}}
						/>
					)}
				</div>

        <div className="modal-details-sec">{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
