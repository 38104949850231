import React, { Fragment, useEffect, useState } from "react";
import {
  usePDF,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  postMethod,
  putMethod,
  getMethod,
} from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";

import sathibazarLogoNew from "../../../assets/icons/sathiBazarBlack1.png";
import useLoadingIndicator from "../loadingIndicator/loadingIndicator";
type objectProp = { objProp: string; value: string };

const styles = StyleSheet.create({
  page: {
    width: "90mm",
    padding: 10,
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    // borderBottomColor: '#3778C2',
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "60%",
    textAlign: "left",
    // borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  logo: {
    width: 150,
    height: 80,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  qty: {
    width: "10%",
    // borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    // borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  tableContainerNew: {
    display: "flex",
  },
  tableRowNew: {
    display:"flex",
    flexDirection:"row",
    justifyContent: "flex-end",
    marginBottom: 5,
  },
  tableCell1New: {
    flex: 1,
  fontWeight: "bold",
  color: "black",
  textAlign:"right",
  },
  tableCell2New: {
    flex: 1,
    color: "black",
    textAlign: "right",
  fontWeight: "bold",
  },
});

const styles1 = StyleSheet.create({
  tableContainer: {
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: 12,
    borderWidth: 1,
    width: 10,
    border: "none",
  },
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    display: "flex",
    fontSize: 18,
    fontWeight: "bold",
    paddingTop: 10,
    paddingLeft: 40,
    paddingRight: 40,
    flexDirection: "column",
    width: 80,
  },
  billheader: {
    // backgroundColor: '#3778C2',
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  // title
  title: {
    fontSize: 20,
    fontWeight: "bold",
    // fontFamily: "Roboto Condensed",
  },
  detailsContainer: {
    padding: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  container: {
    flexDirection: "row",
    // borderBottomColor: '#3778C2',
    // backgroundColor: '#3778C2',
    color: "#fff",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    // flexGrow: 1,
  },
  containerCol: {
    flexDirection: "column",
    // borderBottomColor: '#3778C2',
    // backgroundColor: '#3778C2',
    color: "#fff",
    // borderBottomWidth: 1,
    alignItems: "flex-start",
    height: 100,
    textAlign: "left",
    fontStyle: "bold",
    flexGrow: 1,
    width: "auto",
  },
  // sno
  hsn: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "center",
  },
  productName: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "center",
  },
  priceUnit: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "right",
  },
  qty: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "center",
  },
  amount: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "right",
  },
  gst: {
    flex: 1,
    textTransform: "capitalize",
    textAlign: "right",
  },
});

const InvoiceTableHeader = () => (
  <View style={styles1.container}>
    <Text style={styles1.hsn}>#</Text>
    <Text style={styles1.productName}>product name</Text>
    <Text style={styles1.priceUnit}>price/Unit(Rs)</Text>
    <Text style={styles1.qty}>Qty</Text>
    <Text style={styles1.gst}>GST(%)</Text>
    <Text style={styles1.amount}>Total</Text>
  </View>
);

const GstTableHeader = () => (
  <View style={styles1.containerCol}>
    <Text style={{ flex: 1, textTransform: "capitalize" }}>Taxable Value</Text>
    <Text style={{ flex: 1, textTransform: "capitalize" }}>CGST</Text>
    <Text style={{ flex: 1, textTransform: "capitalize" }}>SGST</Text>
    {/* <Text style={{ flex: 1, textTransform: "capitalize" }}>IGST</Text> */}
    <Text style={{ flex: 1, textTransform: "capitalize" }}>Total Payable</Text>
  </View>
);

const BillHeader = () => {
  return (
    <View style={styles1.billheader}>
      <Text style={styles1.title}>Tax Invoice</Text>
      <Text style={styles1.title}>Original for recipient</Text>
    </View>
  );
};

const Details = (props: any) => {
  const { invoiceDetails, storeDetails } = props;

  return (
    <View style={styles1.detailsContainer}>
      <View>
        {storeDetails.map((data: objectProp) => {
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "220px",
                flexWrap: "wrap",
              }}
            >
              <Text style={{ textTransform: "capitalize" }}>
                {data?.objProp}
              </Text>

              <Text
                style={{
                  display: "flex",
                  textTransform: "capitalize",
                  flex: 3,
                  flexWrap: "wrap",
                }}
              >
                {data?.objProp == "GST: " && data?.value == ""
                  ? "Unregistered"
                  : data?.value}
              </Text>
            </View>
          );
        })}
      </View>
      <View>
        {invoiceDetails.map((data: objectProp) => {
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "220px",
                flexWrap: "wrap",
              }}
            >
              {/* label */}
              <Text
                style={{
                  display: "flex",
                  textTransform: "capitalize",
                  flex: 1,
                }}
              >
                {data.objProp} :
              </Text>
              {/* data */}
              <Text
                style={{
                  display: "flex",
                  textTransform: "capitalize",
                  flex: 2,
                  flexWrap: "wrap",
                }}
              >
                {data.value}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const CoustomerDetails = (props: any) => {
  const { customerDetails } = props;
  const customerDetailsDisplay = [
    "customer_name",
    "address",
    "mobile_number",
    "gst",
  ];

  return (
    <View style={styles1.detailsContainer}>
      {/* customer details */}
      <View style={{ display: "flex", flexDirection: "column" }}>
        {customerDetailsDisplay.map((value) => {
          const data = customerDetails[value];
          if (value === "address" && data === "") {
            return null;
          }

          const displayValue =
            value === "gst" && data === ""
              ? "Unregistered"
              : data !== ""
              ? data
              : "NA";

          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={{
                  padding: "3px",
                  marginBottom: 2,
                  textTransform: "uppercase",
                }}
              >
                {value.includes("_") ? value.replace("_", " ") : value}
              </Text>
              <Text style={{ padding: "3px", marginBottom: 2 }}>
                {": " + displayValue}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

// public and internal note
const PublicInternalNote = (props: any) => {
  const { noteData } = props;
  return (
    <View>
      {noteData.map((note: { objProp: string; value: string }) => {
        return (
          note.value != "" && (
            <View
              style={{
                textTransform: "capitalize",
                display: "flex",
                flexDirection: "row",
                padding: 2,
              }}
            >
              <Text style={{ display: "flex", flex: 1 }}>{note.objProp}</Text>
              <Text style={{ display: "flex", flex: 2, flexWrap: "wrap" }}>
                {note.value}
              </Text>
            </View>
          )
        );
      })}
    </View>
  );
};

const calculation = (add_product: any) => {
  let cgst = 0,
    sgst = 0;
  let overallTotal = 0;
  let overallTotalPayable = 0;
  //get all gst
  const allGst = add_product
    .map((value: any) => {
      return value.gst;
    })
    .reduce((precValue: any, currentVlaue: any) => {
      return Number(precValue) + Number(currentVlaue);
    }, 0);

  return { cgst, sgst, overallTotal, overallTotalPayable };
};

const MyDoc = (props: any) => {
  let overallTotal = 0;
  let overallTotalPayable = 0;
  let overallcgst = 0;
  let overallnet = 0;
  let overallgrandTotal = 0;
  let isSameState = true;
  let commissionValue: any = 0;
  let totalNetAmt: any = 0;
  let igstvalue: any = 0;
  let cgstvalue: any = 0;
  let commissionValueWithGst: any = 0;

  const { storeData, billData } = props;
  // const gstRate: any = localStorage.getItem("rate");
  const hsnCode: any = localStorage.getItem("hsn");
  const gstobject = billData?.customer_details?.gst;

  const {
    customer_details,
    invoice_info,
    add_product,
    sub_total,
    tax,
    total_amount,
    public_note,
    internal_note,
    location_details,
    store_details,
    storeInvoiceNumber,
  } = billData;

  if (gstobject) {
    const firstTwoDigits = billData.store_details.gst_number?.slice(0, 2);
    const firstTwoDigits1 = billData?.customer_details?.gst?.slice(0, 2);

    if (
      (firstTwoDigits1 !== undefined && firstTwoDigits1 !== "") ||
      (firstTwoDigits !== undefined && firstTwoDigits != "")
    ) {
      isSameState = firstTwoDigits === firstTwoDigits1;
    }
  } else {
    const states = billData.location_details.state.toLowerCase().split(" ");
    const address = billData?.customer_details?.address.toLowerCase();
    if (states && address) {
      isSameState = states.every((word: any) => address.includes(word));
    } else {
      isSameState = true;
    }
  }

  const { block, district, pincode, state } = location_details;
  const { name_of_store } = store_details;
  let totalCustomerPay = 0;
  let totalProducts = 0;

  // Inside your component where you're rendering the InvoiceTableRow
  const InvoiceTableRow = (props: any) => {
    const { product, rowIndex } = props;
    const calculateCustomerPay = () => {
      const basePrice = product.customer_pay - product.gst;
      const gstAmount = (basePrice * product.gst) / 100;
      const totalPrice = (basePrice + gstAmount) * product.qty;

      return totalPrice.toFixed(2);
    };

    const customerPay = calculateCustomerPay();

    // Add to the totalCustomerPay and increment totalProducts
    totalCustomerPay += parseFloat(customerPay);
    totalProducts += 1;

    return (
      <View
        style={[
          styles1.container,
          {
            backgroundColor: "transparent",
            color: "black",
            textTransform: "capitalize",
          },
        ]}
      >
        {add_product?.some((product: { hsn: any }) => product.hsn) && (
          <Text style={styles1.hsn}>{product?.hsn}</Text>
        )}
        <Text style={styles1.productName}>{product.product_name}</Text>
        <Text style={styles1.priceUnit}>
          {(
            product?.cafeDiscountedPrice ||
            product?.discounted_price ||
            product?.price + product?.storeMarginAmount
          ).toFixed(2)}
        </Text>
        <Text style={styles1.qty}>{product.qty}</Text>
        <Text style={styles1.gst}>
          {product.gst !== "" && product.gst !== undefined ? product.gst : 0}
        </Text>
        <Text style={styles1.amount}>
          {(
            (product?.cafeDiscountedPrice ||
              product?.discounted_price ||
              product?.price + product?.storeMarginAmount) * product.qty
          ).toFixed(2)}
        </Text>
      </View>
    );
  };
  const storeDetails = [
    { value: name_of_store },
    {
      value:
        block.charAt(0) +
        block.slice(1).toLowerCase() +
        "," +
        district.charAt(0) +
        district.slice(1).toLowerCase(),
    },
    { value: state.charAt(0) + state.slice(1).toLowerCase() + " " + pincode },
    store_details?.gst_number && {
      objProp: "GST: ",
      value: store_details.gst_number,
    },
  ];
  const noteData = [
    {
      objProp: "public note",
      value: public_note,
    },
  ];
  const formattedInvoiceDate = invoice_info.invoice_date
    ? new Date(invoice_info.invoice_date)
    : null;

  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
  });

  const formattedDate = formattedInvoiceDate
    ? formatter.format(formattedInvoiceDate)
    : "";
  // invoice details
  const invoiceDetails = [
    {
      objProp: "INV NO",
      value: storeInvoiceNumber || add_product?.[0]?.storeInvoiceNumber,
    },
    { objProp: "date", value: formattedDate },
  ];

  // calculation
  const { cgst, sgst } = calculation(add_product);
  //tax value
  const calculateIgstBasedonGst = (data: any) => {
    let commVal = 0;
    data?.commission_values?.forEach((item: any) => {
      commVal +=
        ((((Number(data?.cafeDiscountedPrice) || Number(data?.price)) *
          (Number(item?.brokrage_percentage) ||
            Number(item?.brokerage_percentage))) /
          100) *
          Number(data?.qty) *
          Number(data?.commissionGstPercentage)) /
        100;
    });

    return Number(commVal);
  };
  {
    add_product.map((product: any, index: any) => {
      const taxableValue = product?.storePay;
      const totalPayable = (
        Number(
          ((product?.cafeDiscountedPrice ||
            product?.discounted_price ||
            product?.price + product?.storeMarginAmount) *
            product.qty *
            product.gst) /
            100
        ) + Number(calculateIgstBasedonGst(product))
      ).toFixed(2);
      const nettotal = (
        (product?.cafeDiscountedPrice ||
          product?.discounted_price ||
          product?.price + product?.storeMarginAmount) * product?.qty
      ).toFixed(2);

      const cgstamt = (
        ((product?.cafeDiscountedPrice ||
          product?.discounted_price ||
          product?.price + product?.storeMarginAmount) *
          product.qty *
          product.gst) /
        2 /
        100
      ).toFixed(2);
      overallTotal += taxableValue;
      overallTotalPayable += parseFloat(totalPayable);
      overallnet += parseFloat(nettotal);
      overallcgst += parseFloat(cgstamt);
      overallgrandTotal += parseFloat(cgstamt);
      return (
        <View
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 2,
            color: "black",
            textTransform: "capitalize",
          }}
        >
          <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
            {taxableValue}
          </Text>
          <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
            {totalPayable}
          </Text>
        </View>
      );
    });
  }
  // divide
  const Divider = () => (
    <View
      style={{
        borderBottom: "1px dashed black",
        width: "100%",
        marginTop: 5,
        marginBottom: 5,
      }}
    />
  );

  const gstSeparatedProducts: { [key: string]: any[] } = {};

  add_product.forEach((value: any) => {
    const gstKey = value?.gst || "No GST";
    if (!gstSeparatedProducts[gstKey]) {
      gstSeparatedProducts[gstKey] = [];
    }
    gstSeparatedProducts[gstKey].push(value);
  });

  add_product?.forEach((ele: any) => {
    ele?.commission_values?.forEach((item: any) => {
      commissionValue +=
        ((Number(ele?.cafeDiscountedPrice || ele?.price) *
          (Number(item?.brokrage_percentage) ||
            Number(item?.brokerage_percentage))) /
          100) *
        Number(ele?.qty);
    });
  });
  const serviceChargeValue = (data: any) => {
    let commissionValue = 0;
    data?.commission_values?.forEach((item: any) => {
      commissionValue +=
        ((Number(data?.cafeDiscountedPrice || data?.price) *
          (Number(item?.brokrage_percentage) ||
            Number(item?.brokerage_percentage))) /
          100) *
        Number(data?.qty);
    });
    return Number(commissionValue)?.toFixed(2);
  };
  // add_product?.forEach((ele: any) => {
  //   ele?.commission_values?.forEach((item: any) => {
  //     commissionValueWithGst +=
  //       (((Number(ele?.cafeDiscountedPrice || ele?.price) *
  //         (Number(item?.brokrage_percentage) ||
  //           Number(item?.brokerage_percentage))) /
  //         100) *
  //         Number(ele?.qty) *
  //         Number(ele?.commissionGstPercentage)) /
  //       100;
  //   });
  // });
  totalNetAmt = overallnet + commissionValue;
  igstvalue = Number(overallTotalPayable)?.toFixed(2);
  cgstvalue = Number(igstvalue / 2)?.toFixed(2);

  const serviceChargesRateCalculation = (data: any) => {
    let commissionValue = 0;
    data?.forEach((ele: any) => {
      ele?.commission_values?.forEach((item: any) => {
        commissionValue +=
          ((Number(ele?.cafeDiscountedPrice || ele?.price) *
            (Number(item?.brokrage_percentage) ||
              Number(item?.brokerage_percentage))) /
            100) *
          Number(ele?.qty);
      });
    });
    return Number(commissionValue);
  };

  return (
    <>
      <Document>
        <Page size="A4" style={styles1.page}>
          <Image src={sathibazarLogoNew} style={styles.logo} />
          <Divider />
          <BillHeader />
          <Divider />
          <Details
            invoiceDetails={invoiceDetails}
            storeDetails={storeDetails}
          />
          <Divider />
          <CoustomerDetails customerDetails={customer_details} />
          <Divider />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 4,
              color: "black",
              textTransform: "capitalize",
              marginBottom: 5,
            }}
          >
            {add_product?.some((product: { hsn: any }) => product.hsn) && (
              <Text
                style={{
                  display: "flex",
                  flex: 1,
                  width: 20,
                  textAlign: "center",
                  margin: 5,
                }}
              >
                HSN code
              </Text>
            )}
            <Text
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                margin: 5,
              }}
            >
              Product Name/Services Name
            </Text>
            <Text
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                margin: 5,
              }}
            >
              Price
            </Text>

            <Text
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                margin: 5,
              }}
            >
              Unit/Qty
            </Text>
            <Text
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                margin: 5,
              }}
            >
              GST %
            </Text>
            <Text
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                margin: 5,
              }}
            >
              Total
            </Text>
          </View>
          {Object.entries(gstSeparatedProducts).map(
            ([gst, products], index) => (
              <Fragment key={index}>
                {products.map((product: any, rowIndex: number) => (
                  <InvoiceTableRow
                    key={rowIndex}
                    product={product}
                    rowIndex={rowIndex + 1}
                  />
                ))}
              </Fragment>
            )
          )}
          {add_product
            ?.reduce((acc: any, product: any) => {
              const existingProduct = acc.find(
                (p: any) =>
                  p.commissionGstPercentage === product.commissionGstPercentage
              );
              if (existingProduct) {
                existingProduct.commissionGstPercentage =
                  product?.commissionGstPercentage;
                existingProduct.servicePrice += Number(
                  serviceChargeValue(product)
                );
              } else {
                acc.push({
                  ...product,
                  commissionGstPercentage: Number(
                    product?.commissionGstPercentage
                  ),
                  servicePrice: Number(serviceChargeValue(product)),
                });
              }
              return acc;
            }, [])
            ?.map((singleProduct: any, index: any) => (
              <View
                style={[
                  styles1.container,
                  {
                    backgroundColor: "transparent",
                    color: "black",
                    textTransform: "capitalize",
                  },
                ]}
              >
                {add_product?.some((product: { hsn: any }) => product.hsn) && (
                  <Text style={styles1.hsn}>{hsnCode}</Text>
                )}
                <Text style={styles1.productName}>Service Charges</Text>
                <Text style={styles1.priceUnit}>
                  {singleProduct?.servicePrice?.toFixed(2)}
                </Text>
                <Text style={styles1.qty}>1</Text>
                <Text style={styles1.gst}>
                  {singleProduct?.commissionGstPercentage}
                </Text>
                <Text style={styles1.amount}>
                  {singleProduct?.servicePrice?.toFixed(2)}
                </Text>
              </View>
            ))}
          {/* <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              textTransform: "capitalize",
            }}
          >
            <Text
              style={{ fontWeight: "bold", textAlign: "left", width: "100%", }}
            >{`Net total - ${totalNetAmt.toFixed(2)}`}</Text>

            {isSameState ? (
              <Text
                style={{ fontWeight: "bold", textAlign: "left" , width: "100%",}}
              >{`CGST - ${cgstvalue}`}</Text>
            ) : (
              <Text
                style={{ fontWeight: "bold", textAlign: "left" , width: "100%",}}
              >{`IGST - ${igstvalue}`}</Text>
            )}
            {isSameState ? (
              <Text
                style={{ fontWeight: "bold", textAlign: "left" , width: "100%",}}
              >{`SGST - ${cgstvalue}`}</Text>
            ) : (
              ""
            )}
            <Text
              style={{ fontWeight: "bold", textAlign: "left" , width: "100%",}}
            >{`Grand total - ${(
              Number(totalNetAmt) + Number(igstvalue)
            )?.toFixed(2)}`}</Text>
          </View> */}
<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",width:"100%",textAlign:"right"}}>
            <div style={{width:"55%"}}>
              
          <View style={styles.tableContainerNew}>
  <View style={styles.tableRowNew}>
  
 
    <Text style={[styles.tableCell1New, { fontWeight: "bold" ,textAlign: "right" }]}>
    Net Total
    </Text>
    <Text style={[styles.tableCell1New, { fontWeight: "bold",textAlign: "right"  }]}>
      =
    </Text>
    <Text style={[styles.tableCell2New, { textAlign: "right" }]}>
    {totalNetAmt.toFixed(2)}
    </Text>
  </View>

  {isSameState ? (
    <>
      <View style={styles.tableRowNew}>
        <Text style={[styles.tableCell1New, { fontWeight: "bold" }]}>
          CGST
        </Text>
        <Text style={[styles.tableCell1New, { fontWeight: "bold",textAlign: "right"  }]}>
      =
    </Text>
        <Text style={[styles.tableCell2New, { textAlign: "right" }]}>
        {cgstvalue}
        </Text>
      </View>
      <View style={styles.tableRowNew}>
        <Text style={[styles.tableCell1New, { fontWeight: "bold" }]}>
          SGST
        </Text>
        <Text style={[styles.tableCell1New, { fontWeight: "bold",textAlign: "right"  }]}>
      =
    </Text>
        <Text style={[styles.tableCell2New, { textAlign: "right" }]}>
        {cgstvalue}
        </Text>
      </View>
    </>
  ) : (
    <View style={styles.tableRowNew}>
      <Text style={[styles.tableCell1New, { fontWeight: "bold" }]}>
        IGST
      </Text>
      <Text style={[styles.tableCell1New, { fontWeight: "bold",textAlign: "right"  }]}>
      =
    </Text>
      <Text style={[styles.tableCell2New, { textAlign: "right" }]}>
        {igstvalue}
      </Text>
    </View>
  )}

  <View style={styles.tableRowNew}>
    <Text style={[styles.tableCell1New, { fontWeight: "bold" }]}>
      Grand Total
    </Text>
    <Text style={[styles.tableCell1New, { fontWeight: "bold",textAlign: "right"  }]}>
      =
    </Text>
    <Text style={[styles.tableCell2New, { textAlign: "right" }]}>
    {(
              Number(totalNetAmt) + Number(igstvalue)
            )?.toFixed(2)}
    </Text>
  </View>
</View>
</div>
</div>
          <Divider />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 2,
              color: "black",
              textTransform: "capitalize",
            }}
          >
            {add_product?.some((product: { hsn: any }) => product.hsn) && (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                HSN code
              </Text>
            )}

            <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
              Taxable Value
            </Text>

            {isSameState ? (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                CGST%
              </Text>
            ) : (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                IGST%
              </Text>
            )}
            {isSameState ? (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                CGST Rs.
              </Text>
            ) : (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                IGST Rs.
              </Text>
            )}

            {isSameState ? (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                SGST%
              </Text>
            ) : (
              ""
            )}
            {isSameState ? (
              <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
                SGST Rs.
              </Text>
            ) : (
              ""
            )}

            <Text style={{ display: "flex", flex: 1, textAlign: "center" }}>
              Total Payable
            </Text>
          </View>
          <Divider />
          <View>
            <View>
              {Object.entries(gstSeparatedProducts).map(
                ([gst, products], index) => (
                  <View key={index}>
                    {products
                      .reduce((acc: any, product: any) => {
                        const existingProduct = acc.find(
                          (p: any) => p.hsn === product.hsn
                        );
                        if (existingProduct) {
                          existingProduct.price +=
                            (product?.cafeDiscountedPrice ||
                              product?.discounted_price ||
                              product?.price + product?.storeMarginAmount) *
                            product?.qty;
                        } else {
                          acc.push({
                            ...product,
                            price:
                              (product?.cafeDiscountedPrice ||
                                product?.discounted_price ||
                                product?.price + product?.storeMarginAmount) *
                              product?.qty,
                            hsn: product?.hsn || "",
                          });
                        }
                        return acc;
                      }, [])
                      .map((product: any, index: any) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 2,
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          {add_product?.some((p: { hsn: any }) => p.hsn) && (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "center",
                              }}
                            >
                              {product.hsn}
                            </Text>
                          )}
                          <Text
                            style={{
                              display: "flex",
                              flex: 1,
                              textAlign: "right",
                            }}
                          >
                            {(product?.price).toFixed(2)}
                          </Text>
                          {isSameState ? (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {(product.gst / 2).toFixed(2)}
                            </Text>
                          ) : (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {product.gst.toFixed(2)}
                            </Text>
                          )}
                          {isSameState ? (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {(
                                (Number(product?.price) * (product.gst / 2)) /
                                100
                              ).toFixed(2)}
                            </Text>
                          ) : (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {(
                                (Number(product?.price) * product.gst) /
                                100
                              ).toFixed(2)}
                            </Text>
                          )}
                          {isSameState ? (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {(product.gst / 2).toFixed(2)}
                            </Text>
                          ) : (
                            ""
                          )}
                          {isSameState ? (
                            <Text
                              style={{
                                display: "flex",
                                flex: 1,
                                textAlign: "right",
                              }}
                            >
                              {(
                                (Number(product?.price) * (product.gst / 2)) /
                                100
                              ).toFixed(2)}
                            </Text>
                          ) : (
                            ""
                          )}
                          <Text
                            style={{
                              display: "flex",
                              flex: 1,
                              textAlign: "right",
                            }}
                          >
                            {(
                              (Number(product?.price) * product.gst) /
                              100
                            ).toFixed(2)}
                          </Text>
                        </View>
                      ))}
                  </View>
                )
              )}
              {add_product
                ?.reduce((acc: any, product: any) => {
                  const existingProduct = acc.find(
                    (p: any) =>
                      p.commissionGstPercentage ===
                      product.commissionGstPercentage
                  );
                  if (existingProduct) {
                    existingProduct.tax_amount += serviceChargesRateCalculation(
                      [product]
                    );
                    existingProduct.commissionGstPercentage =
                      product?.commissionGstPercentage;
                    existingProduct.igstVal += Number(
                      calculateIgstBasedonGst(product)
                    );
                  } else {
                    acc.push({
                      ...product,
                      tax_amount: serviceChargesRateCalculation([product]),
                      commissionGstPercentage: Number(
                        product?.commissionGstPercentage
                      ),
                      igstVal: Number(calculateIgstBasedonGst(product)),
                    });
                  }
                  return acc;
                }, [])
                ?.map((singleProduct: any, index: any) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 2,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {add_product?.some((p: { hsn: any }) => p.hsn) && (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "center",
                        }}
                      >
                        {hsnCode}
                      </Text>
                    )}
                    <Text
                      style={{
                        display: "flex",
                        flex: 1,
                        textAlign: "center",
                      }}
                    >
                      {singleProduct.tax_amount.toFixed(2)}
                    </Text>
                    {isSameState ? (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {(singleProduct?.commissionGstPercentage / 2).toFixed(
                          2
                        )}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {singleProduct?.commissionGstPercentage.toFixed(2)}
                      </Text>
                    )}
                    {isSameState ? (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {Number(singleProduct?.igstVal / 2).toFixed(2)}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {Number(singleProduct?.igstVal).toFixed(2)}
                      </Text>
                    )}
                    {isSameState ? (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {(singleProduct?.commissionGstPercentage / 2).toFixed(
                          2
                        )}
                      </Text>
                    ) : (
                      ""
                    )}
                    {isSameState ? (
                      <Text
                        style={{
                          display: "flex",
                          flex: 1,
                          textAlign: "right",
                        }}
                      >
                        {Number(singleProduct?.igstVal / 2).toFixed(2)}
                      </Text>
                    ) : (
                      ""
                    )}
                    <Text
                      style={{
                        display: "flex",
                        flex: 1,
                        textAlign: "right",
                      }}
                    >
                      {Number(singleProduct?.igstVal).toFixed(2)}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
          <Divider />
          <View>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 2,
                  color: "black",
                  textTransform: "capitalize",
                }}
              >
                {add_product?.some((p: { hsn: any }) => p.hsn) && (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                )}
                <Text
                  style={{
                    display: "flex",
                    flex: 1,
                    textAlign: "center",
                  }}
                >
                  {totalNetAmt.toFixed(2)}
                </Text>
                {isSameState ? (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                ) : (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                )}
                {isSameState ? (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                ) : (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                )}
                {isSameState ? (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                ) : (
                  ""
                )}
                {isSameState ? (
                  <Text
                    style={{
                      display: "flex",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {}
                  </Text>
                ) : (
                  ""
                )}
                <Text
                  style={{
                    display: "flex",
                    flex: 1,
                    textAlign: "center",
                  }}
                >
                  {igstvalue}
                </Text>
              </View>
            </View>
          </View>
          <Divider />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              textTransform: "capitalize",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 20,
              }}
            >{`For, ${storeDetails[0].value}`}</Text>

            <Text
              style={{ fontWeight: "bold", textAlign: "left" }}
            >{`Authorized Signatory`}</Text>
          </View>
          <PublicInternalNote noteData={noteData} />
        </Page>
      </Document>
    </>
  );
};

export const PDFGenerate = (props: any) => {
  const { billData, storeData } = props;
  const [instance, updateInstance] = usePDF({
    document: <MyDoc billData={billData} storeData={storeData} />,
  });
  if (instance.loading) return <div>Loading ...</div>;
  if (instance.error) return <div>Something went wrong: </div>;
  const handlePrint = (event: any) => {
    event.preventDefault();
    const newWindow = window.open(`${instance?.url}`, "_blank");
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.print();
      };
    }
  };

  return (
    <>
      <a
        href={`${instance?.url}`}
        className="btn btn-primary text-capitalize"
        onClick={handlePrint}
      >
        Print
      </a>
    </>
  );
};
