import { useEffect, useState } from "react";
import "./styles.scss";
import posBanner from "../../../assets/images/pos-banner.png";
import { syncData, syncDataoffline } from "../../../assets/icons";
import { login_db, fetchLoginToDb } from "../../../data/indexedDB";
import { toastNotifications } from "../../../helpers/toastNotifications";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { API_END_POINTS, API_URLS_PATH, SECRET } from "../../../api/urlsConfig";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
export default function () {
  const [empId, setEmpId] = useState("");
  const [empPass, setEmpPass] = useState("");
  const [userListData, setUserListData] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [requestId, setRequestId] = useState("");
  const [isDisableButton, setIsDisableButton] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const storeDisable = queryParams.get("storeDisable");
  const [errorDescription, setErrorDescription] = useState<string | null>(null);
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (storeDisable) {
      toast.error("Login is disabled..Contact Admin!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get("error");
    const errorDescriptionParam = searchParams.get("error_description");
    if (errorParam && errorDescriptionParam) {
      setErrorDescription(capitalizeFirstLetter(errorDescriptionParam));
    }
  }, [location.search]);

  const getUserListFromDB = async () => {
    let data = await login_db.data.toArray();
    if (data.length != 0) {
      setUserListData(data);
      setIsListLoading(false);
    } else {
      fetchLoginToDb();
      getUserListFromDB();
    }
  };

  const logInUser = async () => {
    try {
      const payload = {
        client_id: SECRET.CLIENT_ID,
        redirect_uri: API_URLS_PATH.REDIRECT_URL,
        response_type: "token",
        scope: "openid profile email phone",
      };
      let { data } = await axios.post(
        `${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.AUTHZ_GENERATE}`,
        payload
      );
      const respData = data.data;
      const url =
        `${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.SETTINGS_REQUESTID}`.replace(
          ":reqID",
          respData._id
        );
      const responseId = await axios.post(url);
      setRequestId(respData._id);
    } catch (error) {}
  };

  useEffect(() => {
    logInUser();
  }, []);

  useEffect(() => {
    if (empId != "" && empPass != "") {
      setIsDisableButton(false);
    } else {
      setIsDisableButton(true);
    }
  }, [empId, empPass]);

  const polling = {
    url: "",
    enabled: true,
    interval: 100000,
    timeout: 100000,
  };

  return (
    <Container fluid={true} className="login-container">
      <ToastContainer
        autoClose={1}
        hideProgressBar={true}
        closeButton={false}
      />
      <Row className="form-image-container">
        <Col sm={"12"} md={"6"} className="image-view">
          <img src={posBanner} alt="pos_banner" title="Pos Banner" />
        </Col>
        <Col sm={"12"} md={"6"} className="form-view">
          <Row className="sync">
            <Col xs={"12"}>
              <Online polling={polling}>
                <img
                  className="sync-data"
                  src={syncData}
                  alt="sync_data_online"
                  title="Sync data online"
                />
              </Online>
              <Offline polling={polling}>
                <img
                  className="sync-data"
                  src={syncDataoffline}
                  alt="sync_data_offline"
                  title="Sync data Offline"
                />
              </Offline>
            </Col>
          </Row>

          <Row className="form-container">
            <Col xs="12" className="wrapper" style={{ height: "100%" }}>
              <Container>
                <Row className="form-title">
                  <Col xs="12">Log In</Col>
                </Row>

                <Row className="fields-container">
                  <Col xs={"12"}>
                    <form
                      action={API_URLS_PATH.REACT_APP_LOGIN_SRV}
                      method="post"
                      className="form-login"
                    >
                      <Row>
                        <Col xs={"12"} className="invalid-user-text">
                          {errorDescription && errorDescription}
                        </Col>
                      </Row>
                      <Row className="field">
                        <Col xs={"12"}>
                          <span className="field-title">Employee ID</span>
                          <input
                            name="username"
                            value={empId}
                            onChange={(e) => {
                              setEmpId(e.target.value);
                            }}
                            className="input-field"
                          />
                        </Col>
                      </Row>

                      <Row className="field">
                        <Col xs={"12"}>
                          <span className="field-title">Password</span>
                          <input
                            name="password"
                            type={"password"}
                            value={empPass}
                            onChange={(e) => {
                              setEmpPass(e.target.value);
                            }}
                            className="input-field"
                          />
                          <input
                            type="hidden"
                            value={requestId}
                            name="request_id"
                          />
                        </Col>
                      </Row>

                      <Row className="clear-login-buttons">
                        <Button
                          disabled={isDisableButton}
                          type="submit"
                          title="Login"
                        >
                          Login
                        </Button>

                        <Button
                          onClick={() => {
                            setEmpId("");
                            setEmpPass("");
                          }}
                          title="Clear"
                        >
                          Clear
                        </Button>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
