import React, { useState, useEffect } from "react";
import "./styles.scss";
import { bar, addIcon } from "../../../../assets/icons";
import ProductTable from "./ProductTable";
import RefundProductTable from "./RefundProductTable";
import AddProductModal from "./AddProductModal";
import { ToastContainer } from "react-toastify";
import { toastNotifications } from "../../../../helpers/toastNotifications";
import {
  bill_history_db,
  fetchInventoryToDB,
  inventory_db,
} from "../../../../data/indexedDB";

import {
  calculateSubTotal,
  calculateTotalAmount,
  calculateTotalDiscount,
  calculateTotalTax,
} from "./billingCalculations";
import { Prompt } from "react-router-dom";
import { randomString } from "../../../../helpers/randomString";

export default function (props: any) {
  const { billData, billViewType, setBillModalOpen } = props;
  const [invoiceNo, setInvoiceNo] = useState(() => {
    if (billViewType == "create_bill") {
      return randomString(5);
    } else {
      return billData.invoiceNo;
    }
  });
  const [product_ref, setproduct_ref] = useState(() => {
    if (billViewType == "product_ref") {
      return randomString(5);
    } else {
      return billData.product_ref;
    }
  });
  const [customerName, setCustomerName] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.customerName;
    }
  });
  const [customerContact, setCustomerContact] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.contactNo;
    }
  });
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [productModalHeader, setProductModalHeader] = useState("");
  const [isProductSearchable, setIsProductSearchable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.paymentMethod;
    }
  });
  const [addedproductList, setAddedProductList] = useState(() => {
    if (billViewType == "create_bill") {
      return billData;
    } else {
      return billData.productsDetail.addedProducts;
    }
  });

  const [inventoryListData, setInventoryListData] = useState([]);
  const [isInventoryListLoading, setIsInventoryListLoading] = useState(true);

  const [productSelectedFromItemCode, setProductSelectedFromItemCode] =
    useState({});
  const [itemCode, setItemCode] = useState("");

  const handlePrintAction = () => {
    if (paymentMethod.length == 0) {
      toastNotifications.warning("Select Payment Method");
    } else {
      let data: any = {
        invoiceNo: invoiceNo,
        inventory_id: product_ref,
        date: new Date().toLocaleString(),
        contactNo: customerContact,
        customerName: customerName,
        status: "Done",
        paymentMethod: paymentMethod,
        total: calculateTotalAmount(addedproductList),
        productsDetail: {
          subTotal: calculateSubTotal(addedproductList),
          discount: calculateTotalDiscount(addedproductList),
          tax: calculateTotalTax(addedproductList),
          totalAmount: calculateTotalAmount(addedproductList),
          addedProducts: addedproductList,
        },
      };
      if (billViewType == "create_bill") {
        bill_history_db.data.add(data);
        toastNotifications.success("Billing Successfull");

        window.location.reload();
      }
      if (billViewType == "bill_history" && billData.status == "Pending") {
        toastNotifications.success("Billing Successfull");
        bill_history_db.data.update(billData.invoiceNo, data);

        window.location.reload();
      }
      if (billViewType == "bill_history" && billData.status == "Done") {
        toastNotifications.success("Printing Successfull");
      }

      if (billViewType == "refund_bill") {
        data["lastRefund"] = new Date();
        bill_history_db.data.update(billData.invoiceNo, data);

        toastNotifications.success("Refund Successfull");
        setBillModalOpen(false);
      }
    }
  };
  const handleSaveAction = () => {
    let data = {
      invoiceNo: invoiceNo,
      date: new Date().toLocaleString(),
      contactNo: customerContact,
      customerName: customerName,
      inventory_number: product_ref,
      status: "Pending",
      paymentMethod: "",
      total: calculateTotalAmount(addedproductList),
      productsDetail: {
        subTotal: calculateSubTotal(addedproductList),
        discount: calculateTotalDiscount(addedproductList),
        tax: calculateTotalTax(addedproductList),
        totalAmount: calculateTotalAmount(addedproductList),
        addedProducts: addedproductList,
      },
    };
    if (billViewType == "create_bill") {
      bill_history_db.data.add(data);
      toastNotifications.success("Bill Saved");
      window.location.reload();
    }
    if (billViewType == "bill_history") {
      bill_history_db.data
        .update(billData.invoiceNo, data)
        .then(() => {
          toastNotifications.success("Bill Updated");
          window.location.reload();
        })
        .catch((error: any) => console.log(error));
    }
  };

  const calculateClearButtonVisibility = () => {
    if (billData.status == "Done") {
      return false;
    } else return true;
  };
  const calculateSaveButtonVisibility = () => {
    if (billData.status == "Done") {
      return false;
    } else return true;
  };

  const clcProductEditFncAvailabilty = () => {
    if (
      billViewType == "create_bill" ||
      billViewType == "refund_bill" ||
      billData.status == "Pending"
    ) {
      return true;
    } else return false;
  };
  const clcAddProductFncAvailabilty = () => {
    if (billViewType == "create_bill" || billData.status == "Pending") {
      return true;
    } else return false;
  };

  const getInventoryListFromDB = async () => {
    let data = await inventory_db.data.toArray();
    if (data.length != 0) {
      setInventoryListData(data);
      setIsInventoryListLoading(false);
    } else {
      fetchInventoryToDB();

      getInventoryListFromDB();
    }
  };

  const getProductFromInventoryByQuery = async () => {
    let data = await inventory_db.data
      .where("productId")
      .equals(itemCode)
      .toArray();
    return data;
  };

  const checkBillingInProgress = () => {
    if (
      addedproductList.length > 0 ||
      customerName.length > 0 ||
      customerContact.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getInventoryListFromDB();
  }, []);

  return (
    <div className="bill-container">
      <ToastContainer
        autoClose={1}
        hideProgressBar={true}
        closeButton={false}
      />
      <Prompt
        when={checkBillingInProgress()}
        message={(location) => `Are you sure you want to exit billing?`}
      />

      <div className="product-enter-details-container">
        <div className="product-enter-details-sec">
          <span className="product-enter-details-sec-header">Invoice info</span>
          <div className="product-subdetails-sec">
            <div className="product-subdetails-item-sec">
              <span>Invoice Number</span>
              <input disabled value={billData.invoiceNo ?? invoiceNo} />
            </div>
            <div
              style={{
                marginLeft: "30px",
              }}
              className="product-subdetails-item-sec"
            >
              <span>Invoice Date & Time </span>
              <input
                disabled
                value={billData.date ?? new Date().toLocaleString()}
                style={{ paddingRight: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="product-enter-details-sec">
          <span className="product-enter-details-sec-header">
            Customer Details
          </span>

          <div className="product-subdetails-sec">
            <div className="product-subdetails-item-sec">
              <span>Customer Name</span>
              <input
                disabled={
                  (billViewType == "refund_bill" ||
                    billData.status == "Done") &&
                  true
                }
                defaultValue={billData.customerName ?? ""}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div
              style={{ marginLeft: "30px" }}
              className="product-subdetails-item-sec"
            >
              <span>Mobile Number (+91)</span>
              <input
                maxLength={10}
                disabled={
                  (billViewType == "refund_bill" ||
                    billData.status == "Done") &&
                  true
                }
                defaultValue={billData.contactNo}
                onChange={(e) => setCustomerContact(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {clcAddProductFncAvailabilty() && (
        <div className="add-product-sec">
          <div className="itemcode-barcode">
            <input
              placeholder="Enter Product CODE or Scan Product"
              className="itemcode-barcode-input"
              onChange={(e) => setItemCode(e.target.value)}
              value={itemCode}
            />

            <img
              onClick={async () => {
                if (itemCode.length != 0) {
                  let value = await getProductFromInventoryByQuery();

                  if (value.length > 0) {
                    setProductSelectedFromItemCode(value[0]);
                    setProductModalHeader("Product Details");
                    setIsProductSearchable(false);
                    setIsAddProductModalOpen(true);
                    setItemCode("");
                  } else {
                    toastNotifications.error("Enter correct Item Code");
                  }
                } else {
                  toastNotifications.warning("Enter an Product Code");
                }
              }}
              className="barcode-img"
              src={bar}
              title="Barcode"
              alt="Barcode"
            />
          </div>
          <div
            onClick={() => {
              setIsAddProductModalOpen(true);
              setProductModalHeader("Add Product");
              setIsProductSearchable(true);
            }}
            className="add-new-product-sec"
          >
            <img src={addIcon} alt="add_icon" title="Add Product" />
            <span>Add Product</span>
          </div>
        </div>
      )}
      <div className="added-products-details-sec">
        <ProductTable
          addedproductList={addedproductList}
          setAddedProductList={setAddedProductList}
          productDeletedToast={toastNotifications.warning}
          clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
        />
        <RefundProductTable
          refundedProductList={billData.lastRefund.itemsRefundedDetails}
          setAddedProductList={setAddedProductList}
          productDeletedToast={toastNotifications.warning}
          clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
        />
      </div>
      <div className="total-section">
        <div className="total-row-details">
          <div className="total-row-item">
            Sub Total :
            <span
              style={{
                fontWeight: "bold",
                marginLeft: "45px",
              }}
            >
              {billData.productsDetail.subTotal}
            </span>
          </div>

          <div className="total-row-item">
            Tax :
            <span
              style={{
                marginLeft: "90px",
                fontWeight: "bold",
              }}
            >
              {billData.productsDetail.tax}
            </span>
          </div>
        </div>

        <div className="total-amount">
          Total Amount
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {billData.productsDetail.totalAmount}
          </span>
        </div>
        <div className="total-amount">
          Refunded Amount
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {billData.lastRefund.refundedAmount}
          </span>
        </div>
      </div>
      {addedproductList.length > 0 && (
        <div
          className={
            billViewType == "refund_bill" ? "payment-draft-container" : ""
          }
        >
          {billViewType == "refund_bill" && (
            <input className="refund-comment-input" placeholder="Add Comment" />
          )}
          <div className="payment-draft-sec">
            <select
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={
                (billViewType == "refund_bill" || billData.status == "Done") &&
                true
              }
              value={paymentMethod}
            >
              {billData.status == "Done" ? (
                <option value={billData.paymentMethod}>
                  {billData.paymentMethod}
                </option>
              ) : (
                <option disabled value={""}>
                  Payment Method
                </option>
              )}

              <option value="Cash">Cash</option>
              <option value="CC/DC">CC/DC</option>
              <option value="UPI">UPI</option>
              <option value="Internet Banking">Internet Banking</option>
            </select>

            {calculateClearButtonVisibility() && (
              <button
                onClick={() => {
                  setAddedProductList([]);
                  toastNotifications.warning("Bill Cleared");
                }}
                title="Clear Bill"
              >
                Clear Bill
              </button>
            )}

            {calculateSaveButtonVisibility() && customerName.length > 0 && (
              <button
                onClick={() => {
                  handleSaveAction();
                }}
                title={billViewType == "bill_history" ? "Update" : "Save"}
              >
                {billViewType == "bill_history" ? "Update" : "Save"}
              </button>
            )}

            {billViewType != "refund_bill" && (
              <button
                onClick={() => handlePrintAction()}
                style={
                  paymentMethod.length == 0
                    ? {
                        backgroundColor: "#7aabe2",
                      }
                    : {
                        backgroundColor: "#171f68",
                      }
                }
                title="Print"
              >
                Print
              </button>
            )}
          </div>
        </div>
      )}
      {billViewType == "refund_bill" && (
        <button title="Refund" onClick={() => handlePrintAction()}>
          Refund
        </button>
      )}

      {isAddProductModalOpen && (
        <AddProductModal
          productModalHeader={productModalHeader}
          isProductSearchable={isProductSearchable}
          setIsAddProductModalOpen={setIsAddProductModalOpen}
          productAddedSuccessToast={toastNotifications.success}
          setAddedProductList={setAddedProductList}
          isInventoryListLoading={isInventoryListLoading}
          inventoryListData={inventoryListData}
          productSelectedFromItemCode={productSelectedFromItemCode}
          setProductSelectedFromItemCode={setProductSelectedFromItemCode}
        />
      )}
    </div>
  );
}
