import React, { useEffect, useMemo, useState } from "react";
import { dollar, emptyCart, chart } from "../../../assets/icons";
import "./styles.scss";
import { getMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { Card, Col, Container, Row } from "react-bootstrap";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";

type cardView = {
  icon: any;
  alt: string;
  backgroundColor?: string;
  key: number;
  heading: string;
  count: string | number;
};

type formateResponseData = (payload: any) => Array<any>;

export default function () {
  const [data, setData] = useState<Array<any>>([]);
  const userDetails = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  const formateResponseData: formateResponseData = (payload) => {
    const formatedData = Object.keys(payload).map((value: any) => {
      const obj = payload[value];
      const heading = obj.heading;
      let count = 0;

      if (heading === "Out of Stock Products") {
        count = obj?.number[0]?.count || 0;
      } else {
        const loginType = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0];
        if (loginType === "cafeAdmin") {
          count =
            obj?.number.find(
              (item: { _id?: string[] }) => item?._id?.[0] === "cafeAdmin"
            )?.count || 0;
        } else if (loginType === "storeadmin") {
          count =
            obj?.number.find(
              (item: { _id?: string[] }) => item?._id?.[0] === "storeadmin"
            )?.count || 0;
        }
      }

      let icon_backgroundColor: any =
        heading === "POS Net Revenue"
          ? {
              icon: dollar,
              backgroundColor: "rgb(253, 169, 80)",
              alt: "dollar image",
            }
          : heading === "Total Sale Today"
          ? {
              icon: chart,
              backgroundColor: "rgb(0, 197, 142)",
              alt: "chart image",
            }
          : heading === "Out of Stock Products"
          ? {
              icon: emptyCart,
              backgroundColor: "rgb(6, 33, 95)",
              alt: "cart image",
            }
          : "";

      return { heading, count, ...icon_backgroundColor };
    });

    return formatedData;
  };

  const getAccountData = async () => {
    try {
      const login_type = localStorage.getItem("login-type");

      if (login_type) {
        const loginTypeArray = JSON.parse(login_type);
        let url;
        if (localStorage.getItem("login-type")?.includes("cafeAdmin")) {
          url = API_END_POINTS.POS_CAFESTORE_BILL_ACCOUNT_CAFE.replace(
            ":id",
            userDetails.registeration_id
          )
            .replace(":login_type", loginTypeArray[0])
            .replace(":state_name", userDetails?.location?.state);
        } else {
          url = API_END_POINTS.POS_CAFESTORE_BILL_ACCOUNT.replace(
            ":id",
            userDetails.registeration_id
          ).replace(":login_type", loginTypeArray[0]);
        }

        const resp = await getMethod(url);
        const respData = resp.data;
        const formatedData = formateResponseData(respData);
        setData(formatedData);
      } else {
        console.error("login_type is null");
      }
    } catch (error: any) {
      const errorResponseData = error.response.data;
      const alertMessage =
        errorResponseData.code == 404
          ? "data not found !"
          : "something went wrong!";
      alert(alertMessage);
    }
  };

  useEffect(() => {
    getAccountData();
  }, []);

  const cardView: (data: cardView) => JSX.Element = ({
    heading,
    count,
    icon,
    alt,
    backgroundColor,
    key,
  }) => {
    return (
      <Card style={{ backgroundColor: backgroundColor }} key={key}>
        <Card.Body>
          <Row>
            <Col
              xs="6"
              className="d-flex justify-content-center align-items-center"
            >
              <Row className="d-flex flex-column align-items-center text-center gap-1">
                <Col>{heading}</Col>
                <Col>{parseFloat(count.toString()).toFixed(2)}</Col>
              </Row>
            </Col>

            <Col
              xs="6"
              className="d-flex justify-content-center align-items-center"
            >
              <img src={icon} title="Icon" alt={alt} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container fluid={true} className="mt-3">
      <Row className="card-container-test mr-0">
        {data.map((value, index) => {
          return (
            <Col>
              {cardView({
                heading: value.heading,
                count: value.count,
                icon: value.icon,
                alt: value.alt,
                backgroundColor: value.backgroundColor,
                key: index,
              })}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
