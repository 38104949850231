import { useEffect } from "react";
import axios from "axios";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
  STORAGE_KEYS,
} from "../../../helpers/common";
import { API_END_POINTS, API_URLS_PATH } from "../../../api/urlsConfig";
import { getMethod } from "../../../api/basic_configs/appService";
import { useHistory } from "react-router";
import { ROUTES_PATH } from "../../../routes";
import useLoadingIndicator from "../../../components/Basic/loadingIndicator/loadingIndicator";
import { toastNotifications } from "../../../helpers";

export default function Token() {
  const history = useHistory();
  const { hideIndicator, showIndicator, loadingIndicatorComponent } =
    useLoadingIndicator();

  const getAccessToken = async () => {
    showIndicator();
    if (window.location.hash) {
      const access_token: any = new URLSearchParams(window.location.hash).get(
        "access_token"
      );
      setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, access_token);
      await getuserInfo(access_token);
    }
    hideIndicator();
  };

  const getuserInfo = async (access_token: any) => {
    try {
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const resp = await axios.post(
        `${API_URLS_PATH.APP_BASE_URL}${API_END_POINTS.USER_INFO}`,
        {},
        {
          headers,
        }
      );
      const respData = resp.data.data;
      const metaData = resp.data.meta_data.expiry;
      const tokenExpiryTime = metaData * 1000;
      const timer = tokenExpiryTime - Date.now();
      setLocalStorage("ExpiryTimer", timer);
      setLocalStorage(STORAGE_KEYS.USER_INFO, respData);
      const userDetailsData: any = await getUserDetailsBasedOnSubId(
        respData.sub
      );
      if (userDetailsData?.isStoreDeleted) {
        clearLocalStorage();
        history.replace("/login?storeDisable=true");
        return;
      }
      setLocalStorage(STORAGE_KEYS.USER_DETAILS, userDetailsData);
      const obj = getLocalStorage(STORAGE_KEYS.USER_DETAILS);
      if (obj.login_type == undefined) {
        toastNotifications.warning("you do not have access to POS");
        logoutHandler();
      } else {
        setLocalStorage(STORAGE_KEYS.LOGIN_TYPE, obj.login_type);
      }
      history.replace(ROUTES_PATH.HOME);
    } catch (error) {
      alert("login failed");
      history.replace(ROUTES_PATH.LOGIN);
    }
  };

  const getUserDetailsBasedOnSubId = (subID: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = API_END_POINTS.AUTH_GET_USER_INFO.replace(":sub", subID);
        const resp = await getMethod(url);
        const respData = resp.data;
        resolve(respData);
      } catch (error) {
        reject(error);
      }
    });
  };

  const logoutHandler = () => {
    clearLocalStorage();
    history.replace(ROUTES_PATH.LOGIN);
  };
  useEffect(() => {
    getAccessToken();
  }, []);

  return <>{loadingIndicatorComponent()}</>;
}
