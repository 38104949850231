import React from "react";
import "./styles.scss";
import removeIcon from "../../../assets/icons/delete.svg";
import { productGst } from "./billingCalculations";
import { BILL_VIEW_TYPES, INC_DEC } from "../../../helpers/common";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { deleteMethod } from "../../../api/basic_configs/appService";

let globalNationalAdminGstRate: any;

export const serviceChargesRateCalculation = (data: any, isCafe: any) => {
  let commissionValue = 0;
  data?.forEach((ele: any) => {
    ele?.commission_values?.forEach((item: any) => {
      commissionValue +=
        (((isCafe
          ? Number(ele?.cafeDiscountedPrice || ele?.discounted_price)
          : Number(ele?.price)) *
          (Number(item?.brokrage_percentage) ||
            Number(item?.brokerage_percentage))) /
          100) *
        Number(ele.qty ? ele.qty : ele.quantitySelected);
    });
  });
  return Number(commissionValue);
};

const calculateIgstBasedonGst = (data: any, isCafe: any) => {
  let commissionValue = 0;
  data?.commission_values?.forEach((item: any) => {
    commissionValue +=
      ((((isCafe
        ? Number(data?.cafeDiscountedPrice || data?.discounted_price)
        : Number(data?.price)) *
        (Number(item?.brokrage_percentage) ||
          Number(item?.brokerage_percentage))) /
        100) *
        Number(data?.qty || data?.quantitySelected) *
        Number(data?.commissionGstPercentage)) /
      100;
  });

  return Number(commissionValue);
};

const calculateTax = (products: any, isCafe: boolean) => {
  let totalTax = 0;
  products.forEach((product: any) => {
    let gross;
    if (isCafe) {
      let priceCommissionSum = Number(
        product?.cafeDiscountedPrice || product?.discounted_price
      );
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      gross = priceCommissionSum * qtyValue;
    } else {
      let priceCommissionSum =
        Number(product?.price) + Number(product?.storeMarginAmount);
      gross =
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    }

    let netGst =
      gross * (parseFloat(product.gst) / 100) +
      calculateIgstBasedonGst(product, isCafe);

    totalTax += Number(netGst);
  });

  return Number(totalTax)?.toFixed(2);
};

export const calculateoverallItemTax = (products: any, isCafe: boolean) => {
  let totalamountprice = 0;
  let commissionValue = 0;
  let gross = 0;
  let serviceTaxAmt = 0;

  products.forEach((product: any) => {
    if (isCafe) {
      let priceCommissionSum = Number(
        product?.cafeDiscountedPrice || product?.discounted_price
      );
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      gross = priceCommissionSum * qtyValue;
    } else {
      let priceCommissionSum =
        Number(product?.price) + Number(product?.storeMarginAmount);
      gross =
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    }

    product?.commission_values?.forEach((item: any) => {
      commissionValue +=
        ((Number(
          isCafe
            ? product?.cafeDiscountedPrice || product?.discounted_price
            : product?.price
        ) *
          (Number(item?.brokrage_percentage) ||
            Number(item?.brokerage_percentage))) /
          100) *
        Number(product.qty ? product.qty : product.quantitySelected);
    });

    let netGst =
      gross * (parseFloat(product.gst) / 100) +
      calculateIgstBasedonGst(product, isCafe);

    totalamountprice += Number(gross) + Number(netGst);
  });

  return (Number(totalamountprice) + Number(commissionValue)).toFixed(2);
};
export const calculateTotal = (products: any[], isCafe: boolean) => {
  let total = 0;
  if (isCafe) {
    products.forEach((product) => {
      let priceCommissionSum = Number(
        product?.cafeDiscountedPrice || product?.discounted_price
      );
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      total += priceCommissionSum * qtyValue;
    });
  } else {
    products.forEach((product) => {
      let priceCommissionSum =
        Number(product?.price) + Number(product?.storeMarginAmount);
      total +=
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    });
  }
  return total;
};

export const calculatePerItemTax = (products: any, isCafe: boolean) => {
  let totalTax = 0;
  products.forEach((product: any) => {
    let gross;
    if (isCafe) {
      let priceCommissionSum = Number(
        product?.cafeDiscountedPrice || product?.discounted_price
      );
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      gross = priceCommissionSum * qtyValue;
    } else {
      let priceCommissionSum =
        Number(product?.price) + Number(product?.storeMarginAmount);
      gross =
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    }

    let netGst = gross * (parseFloat(product.gst) / 100);
    let serviceTaxAmt = Number(
      (Number(serviceChargesRateCalculation(products, isCafe)) *
        Number(globalNationalAdminGstRate)) /
        100
    );
    totalTax += Number(netGst) + Number(serviceTaxAmt);
  });
  return totalTax.toFixed(2);
};
export default function ProductTable(props: any) {
  const {
    productDeletedToast,
    addedproductList,
    clcProductEditFncAvailabilty,
    setAddedProductList,
    getSubTotalAmount,
    productSubDetails,
    billViewType,
    setToBePaid,
    setDeletedData,
    billData,
    totalCalculation,
    setCreateBill,
    createBill,
    metaDataQty,
    onClearBillHandler,
    nationalAdminGstRate,
    setPaymentInitiated,
    deleteBillDetails,
    queryType,
  } = props;

  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );

  return (
    <div className="product-table-container">
      <div className="table-column-header">
        <div style={{ width: "5%" }} className="table-item">
          Sno.
        </div>
        <div className="table-item">Product Name</div>
        {isStore && <div className="table-item">Stock No.</div>}
        <div className="table-item">Price/Unit (Rs)</div>
        <div className="table-item">Qty</div>
        <div className="table-item">GST (%)</div>
        <div className="table-item">Total</div>
        {clcProductEditFncAvailabilty() && (
          <div style={{ width: "5%" }} className="table-item"></div>
        )}
      </div>

      <div className="table-row-details">
        {createBill?.add_product && createBill?.add_product?.length > 0 ? (
          <AddedProductTable
            productData={addedproductList}
            productSubDetails={productSubDetails}
            setAddedProductList={setAddedProductList}
            productDeletedToast={productDeletedToast}
            removeIcon={removeIcon}
            clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
            getSubTotalAmount={getSubTotalAmount}
            billViewType={billViewType}
            setToBePaid={setToBePaid}
            setDeletedData={setDeletedData}
            billData={billData}
            totalCalculation={totalCalculation}
            setCreateBill={setCreateBill}
            createBill={createBill}
            metaDataQty={metaDataQty}
            onClearBillHandler={onClearBillHandler}
            nationalAdminGstRate={nationalAdminGstRate}
            setPaymentInitiated={setPaymentInitiated}
            deleteBillDetails={deleteBillDetails}
            queryType={queryType}
          />
        ) : (
          <NoProductsContainer />
        )}
      </div>
    </div>
  );
}

const AddedProductTable = ({
  productDeletedToast,
  removeIcon,
  clcProductEditFncAvailabilty,
  setAddedProductList,
  getSubTotalAmount,
  productSubDetails,
  billViewType,
  setToBePaid,
  setDeletedData,
  billData,
  totalCalculation,
  setCreateBill,
  createBill,
  onClearBillHandler,
  metaDataQty,
  nationalAdminGstRate,
  setPaymentInitiated,
  deleteBillDetails,
  queryType,
}: any) => {
  globalNationalAdminGstRate = nationalAdminGstRate;
  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");

  const deleteBillSingleDetails = async (order_id: any,id:any) => {
    try {
      const url = API_END_POINTS.BILL_DETAILS_SINGLE
      ?.replace(":addProduct_id", id)
      .replace(":order_id", order_id);
      await deleteMethod(url);
    } catch (error) {
      throw error;
    }
  };



  const handleDeleteProduct = (detail: any) => {
    let tempData = createBill.add_product;
    const deletedData = tempData.filter((el: any) => el._id !== detail._id);

    if (deletedData.length === 0) {
      onClearBillHandler();
    } else {
      setCreateBill({
        ...createBill,
        add_product: deletedData,
      });
    }
  };

  const calculateStorePrice = (product: any) => {
    const priceCommissionSum = isCafe
      ? Number(product?.cafeDiscountedPrice || product?.discounted_price)
      : Number(product?.price) + Number(product?.storeMarginAmount);
    return priceCommissionSum;
  };

  const tableTotalAmount = (product: any, qty: any) => {
    const priceCommissionSum = calculateStorePrice(product);
    const totalAmt = priceCommissionSum * qty;
    return totalAmt;
  };

  const totalAmount = createBill?.add_product
    ? calculateTotal(createBill.add_product, isCafe)
    : 0;

  const increment_or_decrement_qty = (
    type: string,
    productItem: any,
    productIndex: number
  ) => {
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
    switch (true) {
      case type == INC_DEC.INCREMENT:
        if (productItem?.quantitySelected) {
          productItem.quantitySelected = (
            parseInt(productItem.quantitySelected, 10) + 1
          ).toString();
        } else {
          productItem.qty = (parseInt(productItem.qty, 10) + 1).toString();
        }
        break;

      case type == INC_DEC.DECREMENT:
        if (productItem?.quantitySelected) {
          productItem.quantitySelected = (
            parseInt(productItem.quantitySelected, 10) - 1
          ).toString();
        } else {
          productItem.qty = (parseInt(productItem.qty, 10) - 1).toString();
        }
        break;
    }
    let tempArray = [...createBill.add_product];

    const modifiedProductItem = {
      ...productItem,
      total_seller_price_with_qty:
        Number(productItem?.finalSellerPrice) *
        Number(productItem?.quantitySelected),
      storeMarginAmount: Number(productItem?.storeMarginAmount),
      storeMarginAmountWithQty:
        Number(productItem?.storeMarginAmount) *
        Number(productItem?.quantitySelected),
      product_total:
        Number(productItem?.customer_pay) *
        Number(productItem?.quantitySelected),
    };
    tempArray.splice(productIndex, 1, modifiedProductItem);
    setCreateBill({ ...createBill, add_product: tempArray });
  };
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );

  return (
    <React.Fragment>
      <div className="product-list-container">
        {createBill?.add_product &&
          createBill.add_product.length > 0 &&
          createBill.add_product.map((item: any, index: any) => {
            const detail = item;
            const firstselect = detail?.ReorderedInventoryData?.batch_no;

            return (
              <div key={index} className="product-row-detail">
                <div style={{ width: "5%" }} className="table-row-item">
                  {index + 1}
                </div>
                <div className="table-row-item">
                  {detail.product_name || detail.name}
                </div>
                {isStore && (
                  <div className="table-row-item">
                    {detail.batch_no || detail.batch_no || firstselect}
                  </div>
                )}
                <div className="table-row-item">
                  {calculateStorePrice(detail).toFixed(2)}
                </div>

                <div className="table-row-item">
                  {billViewType === BILL_VIEW_TYPES.CREATE_BILL ? (
                    <button
                      className="inc-dec"
                      onClick={() => {
                        increment_or_decrement_qty(
                          INC_DEC.DECREMENT,
                          detail,
                          index
                        );
                      }}
                      disabled={
                        (detail?.quantitySelected
                          ? detail.quantitySelected < 2
                          : detail.qty < 2) || detail.disableDecrement
                      }
                      title="Decrease Quantity"
                    >
                      -
                    </button>
                  ) : (
                    ""
                  )}

                  {detail.quantitySelected
                    ? detail.quantitySelected
                    : detail.qty}

                  {billViewType === BILL_VIEW_TYPES.CREATE_BILL ? (
                    <button
                      className="inc-dec-left"
                      onClick={() => {
                        increment_or_decrement_qty(
                          INC_DEC.INCREMENT,
                          detail,
                          index
                        );
                      }}
                      disabled={
                        (isCafe
                          ? (detail?.quantitySelected || detail?.qty) >=
                            detail?.quantity_details?.[0]?.qty
                          : detail?.quantitySelected
                          ? detail.quantitySelected >= detail.current_qty
                          : detail.qty >= detail.current_qty) ||
                        detail.disableIncrement
                      }
                      title="Increase Quantity"
                    >
                      +
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                <div className="table-row-item">
                  {productGst(
                    detail.price,
                    detail.gst,
                    detail.quantitySelected
                      ? detail.quantitySelected
                      : detail.qty
                  )}
                </div>
                <div className="table-row-item">
                  {tableTotalAmount(
                    detail,
                    detail.quantitySelected
                      ? detail.quantitySelected
                      : detail.qty
                  ).toFixed(2)}
                </div>
                {clcProductEditFncAvailabilty() && (
                  <div
                    style={{ width: "5%" }}
                    className="table-row-item"
                    onClick={() => {
                      if (queryType == "resume") {
                        deleteBillSingleDetails(detail?.order_id,detail?.id);
                      }

                      handleDeleteProduct(detail);
                      productDeletedToast("Deleted");
                    }}
                  >
                    <img
                      src={removeIcon}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      title="Remove Product"
                      alt="Remove Product"
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <div className="total-section">
        <div>
          Sub Total :
          <span className="total-section-font-style">
            {totalAmount.toFixed(2)}
          </span>
        </div>
        <div>
          Service Charges :
          <span className="total-section-font-style">
            {serviceChargesRateCalculation(
              createBill?.add_product,
              isCafe
            ).toFixed(2)}
          </span>
        </div>
        <div>
          Tax:
          <span className="total-section-font-style">
            {calculateTax(createBill?.add_product, isCafe)}
          </span>
        </div>
        <div>
          Total Amount :
          <span className="total-section-font-style">
            {(
              Number(totalAmount.toFixed(2)) +
              Number(
                serviceChargesRateCalculation(
                  createBill?.add_product,
                  isCafe
                ).toFixed(2)
              ) +
              Number(calculateTax(createBill?.add_product, isCafe))
            )?.toFixed(2)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

const NoProductsContainer = () => (
  <div className="no-products-section">Add Products</div>
);
