import Modal from "../../../components/Basic/Modal";
import RefundDetails from "./RefundDetails";

export default function RefundDetailsModal(props: any) {
  const { setIsBillDetailsModalOpen, billData } = props;
  return (
    <Modal
      header={`Bill Details - (Refunded)`}
      width={"80%"}
      height={"auto"}
      setOpenModal={setIsBillDetailsModalOpen}
    >
      <RefundDetails
        billData={billData}
        billViewType={"bill_history"}
        setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
      />
    </Modal>
  );
}
