import React, { useEffect } from "react";
import { sathibazarLogoNew } from "../../../assets/icons";
import { toastNotifications } from "../../../helpers";

const PayWithUpi = (props: any) => {
  const {
    generatedToken,
    txnId,
    handleProceed,
    disabledValue,
    upiPaymentSuccess,
    isCustomerNameEmpty,
    isMobileNumberEmpty,
    mobileNumber,
    setUpiMsgString,
    setUpiPaymentSuccess,
    isChecked,
    handlePosSubmit,
    consumerId,
    merchatId,
    amtToBePaid,
  } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleResponse = (res: any) => {
    if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode
    ) {
      const statusCode = res.paymentMethod.paymentTransaction.statusCode;
      if (statusCode === "0300") {
        setUpiMsgString(res?.merchantResponseString);
        setUpiPaymentSuccess(true);
        handleProceed("UPI");
      } else {
        setUpiPaymentSuccess(false);
        toastNotifications.error("Payment Failed ! Try again later");
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const reqJson = {
      features: {
        enableAbortResponse: true,
        enableExpressPay: true,
        enableInstrumentDeRegistration: true,
        enableMerTxnDetails: true,
        enableNewWindowFlow: true,
      },
      consumerData: {
        deviceId: "WEBSH2",
        token: generatedToken,
        returnUrl: "",
        responseHandler: handleResponse,
        paymentMode: "all",
        merchantLogoUrl: sathibazarLogoNew,
        merchantId: merchatId,
        currency: "INR",
        consumerId: consumerId,
        txnId: txnId,
        items: [
          {
            itemId: "first",
            amount: amtToBePaid,
            comAmt: "0",
          },
        ],
        customStyle: {
          PRIMARY_COLOR_CODE: "#45beaa",
          SECONDARY_COLOR_CODE: "#FFFFFF",
          BUTTON_COLOR_CODE_1: "#2d8c8c",
          BUTTON_COLOR_CODE_2: "#FFFFFF",
        },
      },
    };

    window.$.pnCheckout(reqJson);
    if (reqJson?.features.enableNewWindowFlow) {
      window?.pnCheckoutShared?.openNewWindow();
    }
  };

  return (
    <>
      <button
        className="mx-2 btn btn-primary btn-sm"
        onClick={(e: any) => {
          if (isCustomerNameEmpty && !isMobileNumberEmpty) {
            let toastMessage = "";
            switch (true) {
              case isCustomerNameEmpty:
                toastMessage = "Enter customer name";
                break;
              case !isMobileNumberEmpty:
                toastMessage =
                  mobileNumber == ""
                    ? "Enter mobile number"
                    : "Enter Valid Mobile Number";
                break;
            }
            toastNotifications.warning(toastMessage);
          } else {
            isChecked ? handlePosSubmit(e) : handleSubmit(e);
          }
        }}
        disabled={disabledValue}
        title="Pay with UPI"
      >
        {upiPaymentSuccess ? "Paid" : "Pay with UPI"}
      </button>
    </>
  );
};

export default PayWithUpi;
