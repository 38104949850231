import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorage, STORAGE_KEYS } from "../helpers/common";

const Authmiddleware = (props: any) => {
  const {
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isAuthProtected && !getLocalStorage(STORAGE_KEYS.USER_INFO)) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  path: PropTypes.any,
  exact: PropTypes.any,
};

export default Authmiddleware;
