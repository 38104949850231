export const calculatePerItemTotal = (product: any) => {
  const price = parseFloat(product.price);
  const gst = parseFloat(product.gst ? product.gst : 0);
  const discount = parseFloat(product.discount);
  let gross = parseFloat(product.quantitySelected);
  let grossDisc = discount * parseFloat(product.quantitySelected);

  let netValue = gross - grossDisc;
  let netGst = netValue * (gst / 100);
  let totalPay = price * gross;
  return totalPay;
};

export const calculatePerItemSubTotal = (product: any) => {
  let gross =
    parseFloat(product.price ? product.price : product.unitPrice) *
    parseFloat(product.quantitySelected);

  return gross;
};
export const calculatePerItemDiscount = (product: any) => {
  let disc =
    parseFloat(product.discount) * parseFloat(product.quantitySelected);

  return disc;
};
export const calculatePerItemTax = (product: any) => {
  const price = parseFloat(product.customer_pay);
  const gst = parseFloat(product.gst ? product.gst : 0);
  const discount = parseFloat(product.discount);
  let gross = (price ? price : 0) * parseFloat(product.quantitySelected);
  let grossDisc = discount * parseFloat(product.quantitySelected);
  let netValue = gross - grossDisc;
  let netGst = gross * (gst / 100);

  return netGst;
};

export const calculateTotalAmount = (productData: any) => {
  let total = 0;
  for (let i = 0; i < productData.length; i++) {
    total = total + calculatePerItemTotal(productData[i]);
  }

  return Number(total.toFixed(2));
};

export const calculateSubTotal = (productData: any) => {
  let total = 0;
  for (let i = 0; i < productData.length; i++) {
    total = total + calculatePerItemSubTotal(productData[i]);
  }
  return total.toFixed(2);
};
export const calculateTotalDiscount = (productData: any) => {
  let total = 0;
  for (let i = 0; i < productData.length; i++) {
    total = total + calculatePerItemDiscount(productData[i]);
  }
  return total.toFixed(2);
};
export const calculateTotalTax = (productData: any) => {
  let total = 0;
  for (let i = 0; i < productData.length; i++) {
    total = total + calculatePerItemTax(productData[i]);
  }
  return total.toFixed(2);
};

export const productTotal = (customer_pay: any, quantitySelected: number) => {
  return parseFloat((customer_pay * quantitySelected).toFixed(2));
};
export const productGst = (price: any, gst: any, quantitySelected: number) => {
  //convert to numeber
  const convertedPrice = parseFloat(price);
  const convertedGst = parseFloat(gst ? gst : 0);
  //base price= price /(1+gst/100)
  const basePrice = convertedPrice / (1 + convertedGst / 100);
  //nc on base price =baserice*0.02
  let nafedCom = 0.02 * basePrice;
  //calculate the sathi commision
  let sathiCom = 0.05 * basePrice;
  //calculate the store price
  let storePrice = basePrice + nafedCom + sathiCom;
  //gst amount on base price  =base price for the customer *gst
  let gstAmount = convertedGst;
  return parseFloat(gstAmount.toFixed(2));
};

export const calculationBill = (billData: any) => {
  //total amount for individual product

  const productTotalAmount = billData?.map((value: any) => {
    const productData = productTotal(
      value.customer_pay ? value.customer_pay : value.price,
      value.quantitySelected ? value.quantitySelected : value.qty
    );
    return productData;
  });
  //total amount for all the product
  const totalAmount = productTotalAmount?.reduce(
    (initialValue: number, currentValue: number) => initialValue + currentValue,
    0
  );

  //GSt on one product
  const productTotalGst = billData?.map((value: any) => {
    const productData = productGst(
      value.price,
      value.gst,
      value.quantitySelected ? value.quantitySelected : value.qty
    );
    return productData;
  });
  //GSt on all the product
  const totalGstAmount = productTotalGst
    ?.reduce(
      (initialValue: number, currentValue: number) =>
        initialValue + currentValue,
      0
    )
    .toFixed(2);
  const subTotal = (totalAmount - totalGstAmount).toFixed(2);
  return {
    totalAmount,
    totalGstAmount: parseFloat(totalGstAmount),
    subTotal: parseFloat(subTotal),
  };
};
