import React, { useState } from "react";

const TableColumnBarCode = (props: any) => {
  const { cellData } = props;
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  return (
    <>
      <>
        {showFullContent ? (
          <div className="text-break">
            {cellData}
            &nbsp;
            <span className="fw-bold" onClick={toggleContent}>
              {showFullContent ? "Show less" : "Show more"}
            </span>
          </div>
        ) : (
          <div className="text-break">
            {cellData?.substring(0, 20)}
            {cellData?.length > 20 && <>...</>}
            {cellData?.length > 20 && (
              <span className="fw-bold" onClick={toggleContent}>
                {showFullContent ? "Show less" : "Show more"}
              </span>
            )}
          </div>
        )}
      </>
    </>
  );
};

export default TableColumnBarCode;
