import { toast } from "react-toastify";

export const toastNotifications = {
  warning: (title: any) =>
    toast.warning(title, {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      autoClose: 600,
      icon: true,
    }),
  success: (title: any) =>
    toast.success(title, {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      autoClose: 600,
      style: { fontSize: 13, backgroundColor: "#00c58e" },
      icon: true,
    }),

  error: (title: any) =>
    toast.error(title, {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      autoClose: 600,
      icon: true,
    }),
};
