import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { authProtectedRoutes, publicRoutes, ROUTES_PATH } from "./routes";
import Authmiddleware from "./routes/route";
import LoggedIn from "./components/Sections/Layout/LoggedIn";
import LoggedOut from "./components/Sections/Layout/LoggedOut";
import hotkeys from "hotkeys-js";
import "bootstrap/dist/css/bootstrap.min.css";
import { HOT_KEYS } from "./helpers/common";
import useLoadScript from "./hooks/loadScript";

const App = (props: any) => {
  const { loadscript } = useLoadScript();

  useEffect(() => {
    hotkeys(HOT_KEYS.F1, (event, handler) => {
      event.preventDefault();
      window.location.replace(ROUTES_PATH.CREATE_BILL);
    });
    hotkeys(HOT_KEYS.F2, function (event, handler) {
      event.preventDefault();
      window.location.replace(ROUTES_PATH.BILL_HISTORY);
    });
    hotkeys(HOT_KEYS.F3, function (event, handler) {
      event.preventDefault();
      window.location.replace(ROUTES_PATH.INVENTORY);
    });
    hotkeys(HOT_KEYS.F4, function (event, handler) {
      event.preventDefault();
      window.location.replace(ROUTES_PATH.ACCOUNT);
    });
    hotkeys(HOT_KEYS.F5, function (event, handler) {
      event.preventDefault();
      window.location.replace(ROUTES_PATH.BARCODE);
    });
  }, []);

  return (
    <React.Fragment>
      <Router>
        {/* <Redirect to={path} /> */}
        <Switch>
          {publicRoutes.map((route: any, idx: any) => (
            <Authmiddleware
              path={route.path}
              layout={LoggedOut}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={LoggedIn}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};
App.propTypes = {
  layout: PropTypes.any,
};
export default App;
