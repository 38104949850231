import React from "react";
import Modal from "../../Basic/Modal";

export default function BarCodeModal(props: any) {
  const { barcode, setIsBarCodeModalOpen } = props;
  return (
    <>
      <Modal header="Bar Code" width={"60%"}>
        <p className="text-break">
          <b>Barcode : </b>
          {barcode}
        </p>
        <button
          className="btn btn-primary"
          onClick={() => setIsBarCodeModalOpen(false)}
          title="Close"
        >
          Close
        </button>
      </Modal>
    </>
  );
}
