import { CafeNavItems } from "../Navigation/navItems";
import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import { setLocalStorage } from "../../../helpers/common";
import useLoadingIndicator from "../../Basic/loadingIndicator/loadingIndicator";

export default function () {
  const { hideIndicator, showIndicator, loadingIndicatorComponent } =
    useLoadingIndicator();

  const setStoreCafe = (nav: any) => {
    showIndicator();
    setLocalStorage("login-type", [nav.value]);
    window.location.reload();
    hideIndicator();
  };
  return (
    <>
      {loadingIndicatorComponent()}
      <Row>
        <Col xs={"12"} className="nav-links-container mt-5">
          {CafeNavItems.map((nav, index) => (
            <button
              key={index}
              className="nav-item"
              onClick={() => {
                setStoreCafe(nav);
              }}
              title="Navigation Icon"
            >
              <img
                className="nav-item-img"
                src={nav.icon}
                alt="nav-item-img"
                title="Navigation icon"
              />

              <span className="nav-item-name"> {nav.name}</span>
            </button>
          ))}
        </Col>
      </Row>
    </>
  );
}
