import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { userIcon, sathibazarLogoNew } from "../../../assets/icons";
import CircularProgressBar from "../../Basic/CircularProgressBar";
import CircularProgressDone from "../../Basic/CircularProgressDone";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
  STORAGE_KEYS,
} from "../../../helpers/common";
import { ROUTES_PATH } from "../../../routes";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ModalPopup from "../../Basic/Modalpopup/modalpopup";
import useToggleHook from "../../../hooks/useToggleHook";
import useLoadingIndicator from "../../Basic/loadingIndicator/loadingIndicator";
import { toastNotifications } from "../../../helpers/toastNotifications";

export default function () {
  const { hideIndicator, showIndicator, loadingIndicatorComponent } =
    useLoadingIndicator();

  const [clockState, setClockState] = useState(
    new Date().toLocaleTimeString([], { timeStyle: "short" })
  );
  const [isDBSyncDone, setIsDBSyncDone] = useState(false);
  const history = useHistory();
  const userDetails = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_INFO);
  }, []);
  const { hideToggle, showToggle, toggle } = useToggleHook();
  const {
    toggle: dataSyncToggle,
    hideToggle: hideDataSyncToggle,
    showToggle: showDataSyncToggle,
  } = useToggleHook();
  const timer = getLocalStorage("ExpiryTimer");
  useEffect(() => {
    const interval = setInterval(() => {
      setClockState(new Date().toLocaleTimeString([], { timeStyle: "short" }));
    }, 1000);
    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  useEffect(() => {
    setTimeout(() => {
      toastNotifications.error("Session expired. Please log in again.");
      logoutHandler();
    }, timer);
  }, []);

  const logoutHandler = () => {
    clearLocalStorage();
    history.replace(ROUTES_PATH.LOGIN);
  };

  const myaccountHandler = () => {
    showToggle();
  };
  const changeType = (value: any) => {
    showIndicator();
    setLocalStorage("login-type", ["cafeAdmin", "storeadmin"]);
    history.replace(ROUTES_PATH.HOME);
    hideIndicator();
  };

  return (
    <>
      {loadingIndicatorComponent()}
      <Fragment>
        <Row>
          <Col xs={"12"} className="logo-container">
            <div>
              <img
                src={sathibazarLogoNew}
                alt="satibazar_logo"
                style={{ width: "170px" }}
                title="Sathibazar Logo"
              />
            </div>
            <Row>
              <Col xs={"12"} className="text-center">
                {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                  "cafeAdmin"
                ) &&
                getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                  "storeadmin"
                ) ? null : getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0] ===
                  "cafeAdmin" ? (
                  <h2>CAFE</h2>
                ) : (
                  <h2>STORE</h2>
                )}
              </Col>
            </Row>
            <Row className="timer-date-userdropdown">
              <Col>
                <span>{clockState}</span>
              </Col>
              <Col>
                <span>{new Date().toLocaleDateString()}</span>
              </Col>
              <Col className="user-profile-icon">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={userIcon} title="User Icon" alt="user icon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        onClick={() => {
                          myaccountHandler();
                        }}
                        className="dropdown-item"
                      >
                        My Account
                      </a>
                    </li>
                    {getLocalStorage("login-type").length == 1 &&
                      getLocalStorage("user_details").login_type.length > 1 && (
                        <li>
                          <a
                            onClick={() => {
                              changeType("cafeAdmin");
                            }}
                            className="dropdown-item"
                          >
                            Switch to{" "}
                            {getLocalStorage("login-type")[0] == "cafeAdmin"
                              ? "Store"
                              : "Cafe"}
                          </a>
                        </li>
                      )}
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => logoutHandler()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalPopup
          isOpen={dataSyncToggle}
          modalTitle={`Database Sync : ${
            isDBSyncDone ? `Done` : "Please Wait"
          }`}
          onClose={hideDataSyncToggle}
        >
          <Fragment>
            <Container className="d-flex justify-content-center align-items-center">
              {!dataSyncToggle && <CircularProgressBar />}

              {dataSyncToggle && <CircularProgressDone />}
            </Container>
          </Fragment>
        </ModalPopup>

        <ModalPopup isOpen={toggle} modalTitle={"profile"} onClose={hideToggle}>
          <Fragment>
            <Row className="mb-2">
              <Col
                xs={"12"}
                className={"d-flex justify-content-center text-capitalize"}
              >
                <span>user name : </span>
                <span>
                  {userDetails ? userDetails?.name || "Guest" : "Guest"}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={"12"} className={"d-flex justify-content-center"}>
                <span className="text-capitalize">
                  email <span> : </span>
                </span>
                <span>
                  {userDetails ? userDetails?.email || "guest" : "Guest"}
                </span>
              </Col>
            </Row>
          </Fragment>
        </ModalPopup>
      </Fragment>
    </>
  );
}
