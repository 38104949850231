import React from "react";
import "./styles.scss";
import removeIcon from "../../../../assets/icons/delete.svg";
export default function ProductTable(props: any) {
  const {
    productDeletedToast,
    addedproductList,
    clcProductEditFncAvailabilty,
    setAddedProductList,
  } = props;

  return (
    <div className="refund-product-table-container">
      <div className="table-column-header">
        <div style={{ width: "5%" }} className="table-item">
          #
        </div>
        <div className="table-item">Product Name</div>
        <div className="table-item">Unit Price (Rs)</div>
        <div className="table-item">Qty</div>
        <div className="table-item">GST (%)</div>
        <div className="table-item">Total</div>
        {clcProductEditFncAvailabilty() && (
          <div style={{ width: "5%" }} className="table-item"></div>
        )}
      </div>

      <div className="refund-table-row-details">
        {addedproductList.length > 0 ? (
          <AddedProductTable
            productData={addedproductList}
            setAddedProductList={setAddedProductList}
            productDeletedToast={productDeletedToast}
            removeIcon={removeIcon}
            clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
          />
        ) : (
          <NoProductsContainer />
        )}
      </div>
    </div>
  );
}

const AddedProductTable = ({
  productData,
  productDeletedToast,
  removeIcon,
  clcProductEditFncAvailabilty,
  setAddedProductList,
}: any) => {
  const calculatePerItemTotal = (product: any) => {
    let gross =
      parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);
    let grossDisc =
      parseFloat(product.discount) * parseFloat(product.quantitySelected);
    let netValue = gross - grossDisc;
    let netGst = netValue * (parseFloat(product.gst) / 100);
    let totalPay = netValue + netGst;
    return totalPay;
  };

  const calculatePerItemSubTotal = (product: any) => {
    let gross =
      parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);

    return gross;
  };
  const calculatePerItemDiscount = (product: any) => {
    let disc =
      parseFloat(product.discount) * parseFloat(product.quantitySelected);

    return disc;
  };
  const calculatePerItemTax = (product: any) => {
    let gross =
      parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);
    let grossDisc =
      parseFloat(product.discount) * parseFloat(product.quantitySelected);
    let netValue = gross - grossDisc;
    let netGst = netValue * (parseFloat(product.gst) / 100);

    return netGst;
  };

  return (
    <React.Fragment>
      <div className="product-list-container">
        {productData.map((detail: any, index: any) => (
          <div key={index} className="product-row-detail">
            <div style={{ width: "5%" }} className="table-row-item">
              {index + 1}
            </div>
            <div className="table-row-item">{detail.name}</div>
            <div className="table-row-item">
              {detail.unitPrice}/{detail.unit}
            </div>
            <div className="table-row-item">
              {clcProductEditFncAvailabilty() && (
                <div
                  onClick={() => {
                    if (detail.quantitySelected >= 2) {
                      detail.quantitySelected =
                        parseInt(detail.quantitySelected) - 1;

                      setAddedProductList([...productData]);
                    }
                  }}
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    -
                  </span>
                </div>
              )}
              <input
                disabled={!clcProductEditFncAvailabilty()}
                className="table-row-item-input"
                type={"number"}
                min={1}
                value={detail.quantitySelected}
                onChange={(e) => {
                  detail.quantitySelected = e.target.value;
                  setAddedProductList([...productData]);
                }}
              />
              {clcProductEditFncAvailabilty() && (
                <div
                  onClick={() => {
                    detail.quantitySelected =
                      parseInt(detail.quantitySelected) + 1;

                    setAddedProductList([...productData]);
                  }}
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    +
                  </span>
                </div>
              )}
            </div>
            <div className="table-row-item">{detail.discount}</div>
            <div className="table-row-item">{detail.gst}</div>
            <div className="table-row-item">
              {calculatePerItemTotal(detail).toFixed(2)}
            </div>
            {clcProductEditFncAvailabilty() && (
              <div
                style={{ width: "5%" }}
                className="table-row-item"
                onClick={() => {
                  setAddedProductList(
                    productData.filter((item: any) => item.name !== detail.name)
                  );

                  productDeletedToast("Deleted");
                }}
              >
                <img
                  src={removeIcon}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  title="Remove"
                  alt="Remove"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};
const NoProductsContainer = () => (
  <div className="no-products-section">All products were refunded</div>
);
