import React, { useState } from "react";

const useToggleHook = () => {
  const [toggle, setToggle] = useState<boolean>(false);

  const showToggle = () => {
    setToggle(true);
  };

  const hideToggle = () => {
    setToggle(false);
  };

  return { toggle, showToggle, hideToggle };
};

export default useToggleHook;
