import React, { useState, useMemo, useEffect, Fragment } from "react";
import { inventory_db } from "../../../data/indexedDB";
import "./styles.scss";
import TableContainer from "../../../components/Basic/TableContainer/TableContainer";
import { STORAGE_KEYS } from "../../../helpers/common";
import useToggleHook from "../../../hooks/useToggleHook";
import { Button } from "react-bootstrap";
import { getLocalStorage } from "../../../helpers/common";
import { postMethod, getMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import TableColumnBarCode from "./TableColumnBarCode";

export default function () {
  const [inventoryListData, setInventoryListData] = useState([]);
  const [isNewTableVisible, setNewTableVisible] = useState(false);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isDBSyncDone, setIsDBSyncDone] = useState(false);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [reordered, setreordered] = useState([]);
  const { showToggle } = useToggleHook();

  const getDBSyncStatus = () => {
    setTimeout(() => {});
    setTimeout(() => {
      localStorage.setItem("lastSync", String(new Date()));
      setIsDBSyncDone(true);
    }, 2000);
  };

  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  const getInventoryListFromDB = async () => {
    let data = await inventory_db.data.toArray();
    if (data.length != 0) {
      setInventoryListData(data);
      setIsListLoading(false);
    }
  };
  function diff_hours(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
  const syncInventoryData = () => {
    if (localStorage.getItem("lastSync")) {
      let dt1 = new Date(String(localStorage.getItem("lastSync")));
      let dt2 = new Date();
      let diff_hours_value = diff_hours(dt1, dt2);

      if (diff_hours_value > 23) {
        showToggle();
        getDBSyncStatus();
      } else {
        showToggle();
      }
    } else {
      localStorage.setItem("lastSync", String(new Date()));
      showToggle();
      getDBSyncStatus();
    }
  };

  useEffect(() => {
    if (isDBSyncDone) {
      getInventoryListFromDB();
    }
  }, [isDBSyncDone]);

  useEffect(() => {
    syncInventoryData();
    getProductsFromInventory();
  }, []);

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      let response;

      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        response = await postMethod(
          API_END_POINTS.INVENTORY_UTILS_SEARCH,
          data
        );
      } else {
        response = await getMethod(
          API_END_POINTS.GET_HOTCOOK_PRODUCTS_QTY.replace(
            ":state",
            details?.location?.state
          ).replace(":store_id", details.registeration_id)
        );
      }
      const respData = response.data;

      let addSNOToResponse;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        addSNOToResponse = respData.map((value: any, index: number) => {
          return { ...value, SNo: index + 1 };
        });
      } else {
        const filterQtyData =
          respData &&
          respData.filter((qty: any) => qty.quantity_details?.length !== 0);
        addSNOToResponse =
          filterQtyData &&
          filterQtyData.map((value: any, index: number) => {
            return {
              ...value,
              SNo: index + 1,
              qty: value?.quantity_details[0]?.qty,
              customer_pay: value.customer_pay,
              added_date: value.createdAt,
            };
          });
      }
      setInventoryProducts(addSNOToResponse);
    } catch (err: any) {}
  };
  const handleNameClick = async (batchNo: any) => {
    try {
      const response = await getMethod(
        API_END_POINTS.REORDERED_INVENTORY.replace(":batch_id", batchNo)
      );
      const reorderedInventoryData = response.data;
      setreordered(reorderedInventoryData);
    } catch (error) {}
    setNewTableVisible(!isNewTableVisible);
  };

  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "";
  };

  const handleClick = () => {};

  const columns = [
    {
      Header: "S.N0",
      filterable: true,
      accessor: "SNo",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Name",
      accessor: "product_name",
      filterable: true,
      Cell: (cellProps: any) => {
        const batchNo = cellProps.row.original._id;
        return (
          <div
            className="sathi-green sathi-green:hover underline"
            onClick={() => handleNameClick(batchNo)}
          >
            <ProductFeilds {...cellProps} />
          </div>
        );
      },
    },

    {
      Header: "Category",
      accessor: "category",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand",
      accessor: "brand",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? "-" : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Bar Code",
      accessor: "barCode",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? (
          "-"
        ) : (
          <>
            <TableColumnBarCode cellData={cellData} />
          </>
        );
      },
    },

    {
      Header: "Qty Left",
      accessor: "current_qty",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData <= 0 ? (
          <span style={{ color: "red", textTransform: "capitalize" }}>
            out of stock
          </span>
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
  ];
  const columns2 = [
    {
      Header: "Stock No",
      filterable: true,
      accessor: "batch_no",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Category",
      accessor: "category",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand",
      accessor: "brand",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? "-" : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Bar Code",
      accessor: "barCode",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? (
          "-"
        ) : (
          <>
            <TableColumnBarCode cellData={cellData} />
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "product_name",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == cellData ? (
          cellData?.charAt(0)?.toUpperCase() + cellData?.slice(1)
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
    {
      Header: "Qty Left",
      accessor: "current_qty",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData <= 0 ? (
          <span style={{ color: "red", textTransform: "capitalize" }}>
            out of stock
          </span>
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
    {
      Header: "SP(Tax incl)/Unit",
      accessor: "customer_pay",
      filterable: true,
      Cell: (cellProps: any) => {
        const value = Number(cellProps.value).toFixed(2);
        return <ProductFeilds {...cellProps} value={value} />;
      },
    },
    {
      Header: "Discount(%)",
      accessor: "discount",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "GST(%)",
      accessor: "gst",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? 0 : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Date Added",
      accessor: "added_date",
      filterable: true,
      Cell: (cellProps: any) => {
        const data = { value: cellProps?.value?.split("T")[0] };
        return <ProductFeilds {...data} />;
      },
    },
    {
      Header: "Expiry",
      accessor: "expiry_date",
      filterable: true,
      Cell: (cellProps: any) => {
        const data = { value: cellProps?.value?.split("T")[0] };
        return <ProductFeilds {...data} />;
      },
    },
  ];

  const Cafecolumns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Name",
        accessor: "product_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData == cellData ? (
            cellData?.charAt(0)?.toUpperCase() + cellData?.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "SP(Tax incl)/Unit",
        accessor: "customer_pay",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = Number(cellProps.value).toFixed(2);
          return <ProductFeilds {...cellProps} value={value} />;
        },
      },
      {
        Header: "Discount(%)",
        accessor: "discount",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Quantity",
        accessor: "qty",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData <= 0 ? (
            <span style={{ color: "red", textTransform: "capitalize" }}>
              out of stock
            </span>
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "GST(%)",
        accessor: "gst",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData == "" ? 0 : <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Date Added",
        accessor: "added_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
    ],
    []
  );

  const filteredData = inventoryProducts?.filter((ele: any) => {
    return (
      !ele?.isSellerDeleted &&
      !ele?.isStoreDeleted &&
      !ele?.is_deleted &&
      !ele?.isCategoryDeleted
    );
  });

  return (
    <div className="container-fluid">
      <div className="table-row-details"></div>
      {isNewTableVisible ? (
        <Fragment>
          <Button
            className="custom-btn mx-2"
            variant="primary"
            onClick={() => setNewTableVisible(false)}
            title="Back"
          >
            Back
          </Button>
          <TableContainer
            columns={columns2}
            data={reordered}
            isGlobalFilter={true}
            handleClick={handleClick}
            PlaceholderValue={"Search by Name, Barcode , or Category"}
          />
        </Fragment>
      ) : (
        <TableContainer
          columns={
            getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin"
              ? columns
              : Cafecolumns
          }
          data={filteredData}
          isGlobalFilter={true}
          handleClick={handleClick}
          PlaceholderValue={"Search by Name, Barcode , or Category"}
        />
      )}
    </div>
  );
}
