import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../helpers/common";
import { getMethod, postMethod } from "../api/basic_configs/appService";
import { API_END_POINTS } from "../api/urlsConfig";
import { toast } from "react-toastify";

const useRazorPayHook = () => {
  const [keys, setKeys] = useState({ key_id: "" });
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [orderData, setOrderData] = useState({ id: "" });
  const [address, setAddress] = useState({
    _id: "",
    given_name: "",
    family_name: "",
    mobile: "",
  });
  const [totalAmount, setPrice] = useState(0);
  const [upiPaymentSuccess, setUpiPaymentSuccess] = useState(false);
  const [ccPaymentSuccess, setCcPaymentSuccess] = useState(false);
  const [storeDetails, setStoreDetails] = useState<any>();
  const [method, setMethod] = useState("");

  const getPaymentKeys = async () => {
    try {
      setError(null);
      setIsError(false);
      const paymentkeys = await getMethod(API_END_POINTS.GET_PAYMENT_KEY, {
        headers: { "content-Type": "application/json" },
      });
      const response = paymentkeys?.data;
      setKeys(response);
    } catch (error: any) {
      setError(error);
      setIsError(true);
    }
  };
  useEffect(() => {
    getPaymentKeys();
  }, []);

  const verifyPayment = (payload: any) => {
    const storeData = storeDetails.map((details: any) => ({
      ...details,
      amount: Number(totalAmount),
    }));

    return new Promise(async (resolve, reject) => {
      let payloadData = {
        ...payload,
        storeDetails: storeData,
      };
      try {
        const respone = await postMethod(
          API_END_POINTS.POST_VERIFY_PAYMENT,
          payloadData,
          { headers: { "content-Type": "application/json" } }
        );
        const responseData = respone?.data;
        if (responseData) {
          if (responseData && method == "UPI") {
            setUpiPaymentSuccess(true);
          } else if (responseData && method == "CC/DC") {
            setCcPaymentSuccess(true);
          }

          toast.success("Payment Success", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setUpiPaymentSuccess(false);
          setCcPaymentSuccess(false);
        }
        resolve(responseData);
      } catch (error: any) {
        reject(error?.response?.data);
      }
    });
  };

  const openRazorPayPopup = async () => {
    setAddress(getLocalStorage("userdetails"));
    getPaymentKeys();
    setTimeout(() => {
      if (totalAmount && address && keys?.key_id && orderData) {
        const options = {
          key: keys?.key_id,
          amount: Math.ceil(Number(totalAmount) * 100),
          currency: "INR",
          name: `${address?.given_name} ${address?.family_name}`,
          order_id: orderData?.id,
          handler: razorpayResponse,
          prefill: {
            name: `${address?.given_name} ${address?.family_name}`,
            email: getLocalStorage("user_info").email,
            contact: `${address?.mobile}`,
          },
          hidden: {
            email: true,
          },
          theme: {
            color: "#07bc0c",
            hide_topbar: "true",
          },
        };
        if (typeof window !== "undefined") {
          let razorpay = new (window as any).Razorpay(options);
          razorpay.open();
          razorpay.on("payment.failed", function (response: any) {});
        }
      }
    }, 1000);
  };

  useEffect(() => {
    openRazorPayPopup();
  }, [orderData]);

  const razorpayResponse = async (razorpay_response: any) => {
    try {
      const response: any = await verifyPayment(razorpay_response);
      if (response.success) {
        razorpay_response?.close();
      }
    } catch (error) {}
  };

  const getOrderID = async (customer_pay: any, storeData: any) => {
    try {
      const payload = storeData.map((details: any) => ({
        ...details,
        amount: Number(customer_pay),
      }));
      const payloadData = {
        amount: Math.ceil(Number(customer_pay) * 100),
        currency: "INR",
        storeDetails: payload,
      };
      const response = await postMethod(
        API_END_POINTS.POST_CRETAE_ORDER_ITEM,
        payloadData,
        {
          headers: { "content-Type": "application/json" },
        }
      );
      const respData = response?.data;
      if (response?.data && respData?.id && respData?.id !== "") {
        setOrderData(respData);
      } else {
        throw { response: { data: "unable to place order" } };
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      setIsError(true);
      setError(errorResponse);
    }
  };

  const openRazorPay = async (customer_pay: any, method: any, payload: any) => {
    let obj = getLocalStorage("user_info");
    setMethod(method);
    setAddress(obj);
    setPrice(customer_pay);
    setStoreDetails(payload);
    try {
      await getOrderID(customer_pay, payload);
    } catch (error) {}
  };
  return {
    openRazorPay,
    setAddress,
    isError,
    error,
    upiPaymentSuccess,
    ccPaymentSuccess,
    setUpiPaymentSuccess,
    setCcPaymentSuccess,
  };
};
export default useRazorPayHook;
