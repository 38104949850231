import React from "react";
import Modal from "../../../components/Basic/Modal";
import BillDetails from "../../../components/Sections/BillDetails";
import { BILL_VIEW_TYPES } from "../../../helpers/common";

export default function BillDetailsModal(props: any) {
  const { setIsBillDetailsModalOpen, billData, storeData } = props;
  return (
    <Modal
      header={"Bill Details"}
      width={"80%"}
      height={"auto"}
      setOpenModal={setIsBillDetailsModalOpen}
    >
      <BillDetails
        setBillModalOpen={setIsBillDetailsModalOpen}
        billData={billData}
        storeData={storeData}
        billViewType={BILL_VIEW_TYPES.BILL_HISTORY}
      />
    </Modal>
  );
}
