type checkData = (value: any) => boolean;
type stringData = (value: string) => boolean;
export const isNull: checkData = (value: any) => value == null;
export const isUndefined: checkData = (value: any) => value == undefined;
export const isEmptyString: stringData = (value: string) => value == "";

export const STORAGE_KEYS = Object.freeze({
  USER_INFO: "user_info",
  ACCESS_TOKEN: "access_token",
  USER_DETAILS: "user_details",
  LOGIN_TYPE: "login-type",
});

export const setLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key: string) => {
  const data: any = localStorage.getItem(key);
  return !isNull(data) ? JSON.parse(data) : null;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const HOT_KEYS = Object.freeze({
  F1: "f1",
  F2: "f2",
  F3: "f3",
  F4: "f4",
  F5: "f5",
  F6: "f6",
});

export const PAYMENTMETHOD: any = Object.freeze({
  CASH: "Cash",
  UPI: "UPI",
  CC_DC: "CC/DC",
});

export const BILL_VIEW_TYPES = Object.freeze({
  BILL_HISTORY: "bill_history",
  CREATE_BILL: "create_bill",
  REFUND_BILL: "refund_bill",
});

export const INC_DEC = Object.freeze({
  INCREMENT: "increment",
  DECREMENT: "decrement",
});

export const PAYMENT_STATUS = Object.freeze({
  PENDING: "pending",
  PAID: "paid",
});
